import React from 'react';
import DatePicker from 'react-datepicker';
import { getYear, getMonth } from 'date-fns';
import { InputAdornment, TextField } from '@mui/material';
import { CalendarToday } from '@mui/icons-material';
import { range } from 'lodash';  // Import range from lodash for range utility
import 'react-datepicker/dist/react-datepicker.css';

const CustomDatePicker = ({ selectedDate, setSelectedDate, minDate, label, disabled, helperText}) => {
  const years = range(2016, getYear(new Date()) + 10, 1);
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  

  return (
    <DatePicker
    disabled={disabled}
    customInput={
      <TextField
          label={label}
          helperText={helperText}
          InputProps={{
          startAdornment: (
              <InputAdornment position="start">
              <CalendarToday />
              </InputAdornment>
          ),
          }}
          readOnly
      />}
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div
          style={{
            margin: 10,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
            {"<"}
          </button>

          <select
            value={getYear(date)}
            onChange={({ target: { value } }) => changeYear(Number(value))}
          >
            {years.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <select
            value={months[getMonth(date)]}
            onChange={({ target: { value } }) =>
              changeMonth(months.indexOf(value))
            }
          >
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
            {">"}
          </button>
        </div>
      )}
      selected={selectedDate}
      className="date-picker"
      dateFormat="yyyy-MM-dd"
      minDate={new Date(minDate)}
      onChange={(date) => setSelectedDate(date)}
    />
  );
};

export default CustomDatePicker;

// import React from 'react';
// import DatePicker from 'react-datepicker';
// import { InputAdornment, TextField } from '@mui/material';
// import { CalendarToday } from '@mui/icons-material';
// import 'react-datepicker/dist/react-datepicker.css';
// import { addMonths } from 'date-fns';

// const CustomDatePicker = ({ selectedDate, setSelectedDate, minDate }) => {
//   return (
//     <DatePicker
//       selected={selectedDate}
//       onChange={(date) => setSelectedDate(date)}
//       renderCustomHeader={({
//         date,
//         changeYear,
//         changeMonth,
//         decreaseMonth,
//         increaseMonth,
//         prevMonthButtonDisabled,
//         nextMonthButtonDisabled,
//       }) => (
//         <div
//           style={{
//             margin: 10,
//             display: 'flex',
//             justifyContent: 'center',
//             alignItems: 'center',
//           }}
//         >
//           <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
//             {"<"}
//           </button>
//           <select
//             value={date.getFullYear()}
//             onChange={({ target: { value } }) => changeYear(Number(value))}
//           >
//             {Array.from({ length: 10 }, (_, i) => {
//               const year = new Date().getFullYear() - i;
//               return (
//                 <option key={i} value={year}>
//                   {year}
//                 </option>
//               );
//             })}
//           </select>

//           <select
//             value={date.getMonth()}
//             onChange={({ target: { value } }) => changeMonth(Number(value))}
//           >
//             {[
//               'January',
//               'February',
//               'March',
//               'April',
//               'May',
//               'June',
//               'July',
//               'August',
//               'September',
//               'October',
//               'November',
//               'December',
//             ].map((month, index) => (
//               <option key={index} value={index}>
//                 {month}
//               </option>
//             ))}
//           </select>

//           <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
//             {">"}
//           </button>
//         </div>
//       )}
//       minDate={minDate} // Prevents selection of past dates
//       maxDate={addMonths(new Date(), 12)} // Optional: limit to 12 months
//       className="date-picker"
//       dateFormat="yyyy-MM-dd"
//     />
//   );
// };

// export default CustomDatePicker;
