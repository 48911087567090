import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchServiceInstances, setInitialServiceTotalCount } from '../../store/serviceInstanceSlice';
import { Box, Button, Typography, IconButton, CircularProgress, Tooltip } from '@mui/material';
import DataTable from '../global/DataTable';
import ServiceInstancesDetails from './ServiceInstancesDetails';
import { Link } from 'react-router-dom';
import DynamicIcon from '../global/DynamicIcon'
import AlertComponent from '../global/AlertComponent';
import { format } from 'date-fns';

const ServiceInstanceComponent = () => {
  const isMounted = useRef(false);
  const dispatch = useDispatch();
  const { userRole } = useSelector((state) => state.user);
  const { serviceInstanceList = [], serviceError, loading } = useSelector((state) => state.serviceInstance || {});  
  // const limit = 10; // Number of records per request
  // const offset = serviceInstanceList.length;
  const [selectedInstance, setSelectedInstance] = useState('');

  const columns = useMemo(() => [
    { accessorKey: 'id', header: 'UUID', filterable: true, minSize: 7, size: 15,
      Cell: (params) => (
        <Tooltip title={params.row.id}>
          <span>{params.row.id}</span>
        </Tooltip>
      ),
     },
    { accessorKey: 'name', header: 'Instance Name', filterable: true, sortable: true, minSize: 10, size: 20 },
    { accessorKey: 'state', header: 'State', filterable: true, minSize: 10, size: 20,
      Cell: ({ row }) => (
      <Typography variant='body2'>
        {row.original.state.replace(/([a-z])([A-Z])/g, "$1 $2").replace(/^\w/, (c) => c.toUpperCase())}
      </Typography>
    ),},
    { accessorKey: 'startDate', header: 'Start Date', filterable: true, sortable: true, minSize: 10, size: 20,
      Cell: (params) => {
        return (
          <Typography variant='body1'>
            {format(new Date(params.row._valuesCache.startDate), 'yyyy-MM-dd')}
          </Typography>
        );
      },
     },
     { accessorKey: 'csrProject', header: 'Customer Project', filterable: true, sortable: true, minSize: 10, size: 20 },
    {
      accessorKey: 'details',
      header: 'Details',
      enableColumnFilter: false,
      filtering: false,
      sortable: false,
      minSize: 5, size: 15,
      enableColumnActions: false,
      Cell: (params) => (
        <IconButton sx={{ py:0}}>
          <Link to={`/serviceinstance/${params.row.id}`} style={{textDecoration: 'none'}}>
            <DynamicIcon iconName="Visibility" fontSize="medium" sx={{ color: "#e20074", fontSize: '20px' }} />
          </Link>
        </IconButton>
      ),
    },
    {
      accessorKey: 'modify',
      header: 'Modify',
      sortable: false,
      minSize: 10, size: 15,
      enableColumnFilter: false,
      enableColumnActions: false,
      Cell: (params) => (
        <IconButton  disabled onClick={() => handleModify(params.row.id)}>
          <DynamicIcon iconName="EditOutlined" fontSize="medium" sx={{ fontSize: '20px'}} />
        </IconButton>
      ),
    },
    {
      accessorKey: 'delete',
      header: 'Delete',
      sortable: false,
      minSize: 10, size: 15,
      enableColumnFilter: false,
      enableColumnActions: false,
      Cell: (params) => (
        <IconButton disabled onClick={() => handleDelete(params.row.id)}>
          <DynamicIcon iconName="DeleteOutlined" fontSize="medium" sx={{ fontSize: '20px'}} />
        </IconButton>
      ),
    },
  ], []);
  
  // Pagination state
  // const [pageIndex, setPageIndex] = useState(0); // Current page index
  // const [pageSize, setPageSize] = useState(10); // Rows per page

  // Fetch data from the API
  // const fetchData = () => {
  //    if (!isMounted.current) {
  //     console.log("Component mounted!");
  //     isMounted.current = true;
  //     dispatch(setInitialServiceTotalCount());
  //     dispatch(fetchServiceInstances({ limit: 10, offset: 0 }));
  //   }
  // };
  const handleModify = (row) => {
    console.log('Modify row:', row);
  };
  
  const handleDelete = (row) => {
    console.log('Delete row:', row);
  };

  useEffect(() => {
    const fetchData = (isInitial = false) => {
      if (isInitial && !isMounted.current) {
        console.log("Component mounted - initial fetch");
        isMounted.current = true;
        dispatch(setInitialServiceTotalCount());
        dispatch(fetchServiceInstances({ limit: 150, offset: 0 }));
      } else if (!isInitial) {
        console.log("Fetching data at regular interval...");
        dispatch(setInitialServiceTotalCount());
        dispatch(fetchServiceInstances({ limit: 150, offset: 0 }));
      }
    };
  
    // Parse the interval from environment variables
    const timer = Number(process.env.REACT_APP_FETCHDATA_INTERVAL) || 300000; // Default to 5 minutes if not set or invalid
  
    // Initial API call
    fetchData(true);

    // Set up interval for subsequent API calls
    const interval = setInterval(() => {
      fetchData(false);
    }, timer);
  
    // Cleanup interval on unmount
    return () => clearInterval(interval);
  }, [dispatch]);

  // const handlePaginationChange = (newPageIndex, newPageSize) => {
  //   setPageIndex(newPageIndex);
  //   setPageSize(newPageSize);    
  //   dispatch(fetchServiceInstances({ limit: 10, offset: newPageIndex * 10 || 0}));
  // };

  // const handleNextPage = () => {
  //   console.log(Math.ceil(totalServiceInstanceCount / pageSize), 'totalServiceInstanceCount', totalServiceInstanceCount);
  //   if (pageIndex <= totalServiceInstanceCount) {
  //     handlePaginationChange(pageIndex + 1, pageSize);
  //   }
  // };

  // const handlePrevPage = () => {
  //   if (pageIndex > 0) {
  //     handlePaginationChange(pageIndex - 1, pageSize);
  //   }
  // };

  const handleSelectedInstance = (serviceId) => {
    setSelectedInstance(serviceId);
  };

  const handleCreateNewserviceInstance = () => {
    setSelectedInstance('');
    // setCreateNewserviceInstance(true);
  };

  // const handleCloseNewserviceInstance = () => {
  //   // setCreateNewserviceInstance(false);
  // };
  
  return (
    <Box>
      {/* Show welcome message when no order is selected and not creating a new service order */}
        <Box sx={{ mt: 2 }}>
        {selectedInstance ? (
            // Display service order details if an order is selected
            <ServiceInstancesDetails selectedInstance={selectedInstance} setSelectedInstance={handleSelectedInstance} />
          ) : (
            <Box sx={{ boxShadow: 3, borderRadius: 2, p: 2, overflow: 'hidden' }}>
              <Typography variant="h5">Service Instances List</Typography>
  
              {/* Button to create a new service order */}
              {!selectedInstance && (
                <Box sx={{ display: 'flex', justifyContent: 'start', my: 2, gap:2 }}>
                  <Button disabled={userRole !== 'Admin'} onClick={handleCreateNewserviceInstance} variant="contained" size="medium" color="secondary">
                    <Link to="/createNew" style={{ color: 'inherit', textDecoration: 'none' }}>
                      Create New Service Instance
                    </Link>
                  </Button>
                </Box>
              )}
              {/* Handle serviceError, loading, or display the data table */}
              {serviceError?.reason ? (
                <AlertComponent
                  fullWidth
                  severity={serviceError.severity}
                  title={serviceError.reason}
                  description={serviceError.message}
                />
              ) : loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', height: "500px", alignItems: 'center' }}>
                  <CircularProgress color="secondary" />
                </Box>
              ) : (
                <Box>
                  <DataTable
                  columns={columns}
                  data={serviceInstanceList}
                  enableStickyHeader
                  getRowId={(row) => row.id}
                  showDownloadButton
                  showPrintButton
                  enableExpandAll={false}
                  enableTopToolbar={true}
                />

                  {/* <DataTable
                    columns={columns}
                    data={serviceInstanceList}
                    rowCount={totalServiceInstanceCount}
                    enableStickyHeader
                    getRowId={(row) => row.id}
                    // hideFooterPagination
                    // enablePagination={false}
                    // enableBottomToolbar={false}
                    // showDownloadButton
                    showPrintButton
                    enableExpandAll={false}
                    enableTopToolbar={true}
                    // limit={pageSize} // Rows per page
                    // offset={pageIndex * pageSize} // Offset calculation
                    // hasMore={hasMore}
                    // handlePaginationChange={handlePaginationChange}
                  /> */}
                   {/* Add custom pagination buttons */}
                    {/* <Box sx={{ display: "flex", justifyContent: "end", marginTop: "10px" }}>
                      <Typography variant="body2" sx={{ marginTop: '4px', mr: 1}}>
                        Row per page 10
                        <IconButton sx={{ p: 0}} disabled onClick={handlePrevPage} variant="standard">
                          <DynamicIcon iconName="ArrowDropDown"/>
                        </IconButton>
                      </Typography>
                      <IconButton onClick={handlePrevPage} color="icons" disabled={pageIndex === 0} variant="standard">
                        <DynamicIcon iconName="ArrowBackIos" sx={{ fontSize: '16px' }} />
                      </IconButton>
                      <Box sx={{ margin: "0 10px", display: "flex", alignItems: "center", fontSize: '14px', }}>
                        {pageIndex * 10 + 1} - {pageIndex * 10 + 10}
                      </Box>
                      <IconButton
                        color="icons"
                        onClick={handleNextPage}
                        disabled={!hasMore}
                        variant="standard"
                      >
                        <DynamicIcon iconName="ArrowForwardIos" sx={{ fontSize: '16px' }} />
                      </IconButton>
                    </Box> */}
                </Box>
              )}
            </Box>
          )}
        </Box>
      {/* )} */}
    </Box>
  );
};

export default ServiceInstanceComponent;
