import React, { useState } from 'react';
import { Button, TextField, Typography, Box, Grid, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import DynamicIcon from '../../global/DynamicIcon';
import { setFormData, setActiveStep, setCheckFormType } from '../../../store/createNewServiceOrder';

const CreateNewOrderWizard = () => {
  const [jsonFile, setJsonFile] = useState(null);
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Handle file input change
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === 'application/json') {
      setJsonFile(file);
      setError('');
    } else {
      setJsonFile(null);
      setError('Please upload a valid JSON file.');
    }
  };

  const handleOnWizard = () => {
    navigate('/createServiceForm')
    dispatch(setCheckFormType('wizard'));
    dispatch(setActiveStep(0));
    // setStepByStepWizard(true);
  }


  // // Handle form submit
  const handleSubmit = (event) => {
    event.preventDefault();
    if (jsonFile) {
      const reader = new FileReader();
      console.log(reader, 'reader');
      
      reader.onload = (e) => {
        const jsonData = JSON.parse(e.target.result);
        if (!jsonData.hasOwnProperty('category') || !jsonData.hasOwnProperty('serviceOrderItem') || !jsonData.hasOwnProperty('requestedStartDate')) {
          setError("Error: The format is not correct to upload the new service. Please recheck the format.");
        }
        else {
          dispatch(setFormData(jsonData));
          dispatch(setCheckFormType('import'));
          dispatch(setActiveStep(2));
          navigate('/createServiceForm')
        }
      };
      reader.readAsText(jsonFile);
    } else {
      setError('Please upload a JSON file before submitting.');
    }
  };

  return (
    <Box sx={{ maxWidth: '95%' }}>
      <Grid
        container
        spacing={3}
      >
        {/* Back Button */}
        <Grid item xs={9}>
          <Button
            sx={{ mb: 2 }}
            onClick={() => navigate(-1)}
            variant="outlined"
            color="secondary"
          >
            <DynamicIcon iconName="ArrowBack" fontSize="medium" />
          </Button>
        </Grid>

        {/* Form */}
      </Grid>
        <Box sx={{maxWidth: '1024px', m: 'auto'}}>
        {/* Main Heading */}
        <Typography variant="h4" mb={4}>
          Create New Service
        </Typography>

        {/* First Section */}
        <Box display="flex" flexDirection="row" justifyContent="space-between" mb={4}>
          <Typography variant="h6">Step By Step Checkout</Typography>
          {/* <Link to="/createServiceForm" style={{ textDecoration: 'none'}}> */}
            <Button onClick={handleOnWizard} variant="contained" color="secondary">
              Wizard
            </Button>
          {/* </Link> */}
        </Box>

        {/* Divider with OR */}
      <Box display="flex" alignItems="center" mb={4}>
        <Divider sx={{ flexGrow: 1 }} />
        <Typography variant="body1" sx={{ mx: 2 }}>
          OR
        </Typography>
        <Divider sx={{ flexGrow: 1 }} />
      </Box>

        {/* Second Section */}
        <Box>
          <Typography variant="h6" mb={2}>Import from JSON File</Typography>
          <form onSubmit={handleSubmit}>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              {/* File Input */}
              <Box sx={{ flex: 'grow'}}>
                <TextField
                  type="file"
                  inputProps={{ accept: '.json' }}
                  fullWidth
                  variant="outlined"
                  onChange={handleFileChange}
                  helperText="Only JSON files are allowed"
                />
                {/* Error Message */}
                {error && <Typography color="error" mt={2}>{error}</Typography>}
              </Box>

              {/* Submit Button */}
              <Box mt={2}>
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  disabled={!jsonFile}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </form>
          {/* <ReactJson src={formData} name={null} style={{ fontSize : 13 }} collapsed={true} indentWidth={3} displayDataTypes={false} displayObjectSize={false} /> */}
        </Box>
      </Box>
    </Box>
  );
};

export default CreateNewOrderWizard;
