import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Button,
  Grid,
  IconButton, Paper, CircularProgress
} from '@mui/material';
import { fetchServiceOrderByID } from '../../store/serviceOrderSlice';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DynamicIcon from '../global/DynamicIcon';
import AlertComponent from '../global/AlertComponent';
import { formatInTimeZone } from 'date-fns-tz';
import { Link } from 'react-router-dom';

const ServiceOrderDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const serviceOrderDetails = useRef(null);
  const {serviceOrderById = [], relatedInstances = [], loading, error} = useSelector((state) => state.serviceOrder || {});
  const [expanded, setExpanded] = useState(true);

  useEffect(() => {
    if (id) {
      dispatch(fetchServiceOrderByID(id));
    }
  }, [dispatch, id]);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  const formatDateTime = (date) => {
    if (!date) return 'N/A';
    return `${formatInTimeZone(
      date,
      'UTC',
      `yyyy-MM-dd' hrs. 'HH:mm:ss`
    )} UTC` ;
  };

  const handleUpdateServiceOrder = () => {
    console.log("Update Service Order clicked");
  };

  const handleDeleteServiceOrder = () => {
    console.log("Delete Service Order clicked");
  };
  const handleModify = (row) => {
    console.log('Modify row:', row);
  };

  const renderValue = (value) => {
    if (typeof value === 'object' && value !== null) {
      return JSON.stringify(value);
    }
    return value || 'N/A';
  };

  if (loading) {
    return <Box sx={{ padding: '2rem', ml: '1px' }}>
      <CircularProgress color='secondary' />
    </Box>;
  }

  return (
    <Box >
      {/* <Box sx={{m: 'auto', width: '95%', maxWidth: '1024px', textAlign: 'center' }}> */}
      <Button sx={{ mb: 2 }} onClick={() => navigate(-1)} variant="outlined" color="secondary">
        <DynamicIcon iconName="ArrowBack" sx={{ fontSize: '18px'}} />
      </Button>
      {error && error?.message ? (
      <AlertComponent
        severity="error"
        title={error?.reason}
        description={error?.message}
      />
    ) : !loading ? (
      <Box sx={{m: 'auto', width: '95%', maxWidth: '1024px', textAlign: 'center' }}>
        <Box sx={{ 
            maxWidth: {
              xs: '100%', // Extra small screens
              sm: '600px', // Small screens
              md: '800px', // Medium screens
              lg: '1024px', // Large screens
            }, textAlign: 'center'}}>
          <Accordion expanded={expanded} onChange={handleChange} sx={{ borderRadius: 1 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5">Service Order Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer component={Paper} sx={{ width: '100%', borderRadius: 0 }}>
                <Table sx={{ tableLayout: 'fixed' }} size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow sx={{ backgroundColor: '#D3D3D3'}}>
                      <TableCell  sx={{ color: '#000', fontWeight: '600',fontSize: '14px'}}>Field</TableCell>
                      <TableCell  sx={{ color: '#000', fontWeight: '600',fontSize: '14px'}}>Value</TableCell>
                      <TableCell  sx={{ color: '#000', fontWeight: '600',fontSize: '14px'}}>Modify</TableCell>
                    </TableRow>
                  </TableHead>
                  {serviceOrderById?.length > 0 && serviceOrderById.map((value, index) => (
                  <TableBody sx={{ textAlign: 'center'}} key={index}>
                      <TableRow>
                        <TableCell  sx={{ fontWeight: 600, fontSize: '14px'}} component="th" scope="row">UUID</TableCell>
                        <TableCell>{renderValue(value.id)}</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell  sx={{ fontWeight: 600, fontSize: '14px'}} component="th" scope="row">Category</TableCell>
                        <TableCell>{renderValue(value.category)}</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell  sx={{ fontWeight: 600, fontSize: '14px'}} component="th" scope="row">Description</TableCell>
                        <TableCell>{renderValue(value.description)}</TableCell>
                        <TableCell>
                          <IconButton
                            sx={{ padding: 0}}
                            disabled
                            onClick={() => handleModify(value.description)}
                          >
                            <DynamicIcon iconName="EditOutlined" sx={{ fontSize: '18px'}} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell  sx={{ fontWeight: 600, fontSize: '14px'}} component="th" scope="row">External Reference ID</TableCell>
                        <TableCell>{renderValue(value.externalId)}</TableCell>
                        <TableCell>
                          <IconButton
                            sx={{ padding: 0}}
                            disabled
                            onClick={() => handleModify(value.externalId)}
                          >
                            <DynamicIcon iconName="EditOutlined" sx={{ fontSize: '18px'}} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell  sx={{ fontWeight: 600, fontSize: '14px'}} component="th" scope="row">Notification Contact</TableCell>
                        <TableCell>{renderValue(value.notificationContact)}</TableCell>
                        <TableCell>
                          <IconButton
                            sx={{ padding: 0}}
                            disabled
                            onClick={() => handleModify(value.notificationContact)}
                          >
                            <DynamicIcon iconName="EditOutlined" sx={{ fontSize: '18px'}} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ fontWeight: 600, fontSize: '14px'}} component="th" scope="row">State</TableCell>
                        <TableCell>{renderValue(value.state)}</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ fontWeight: 600, fontSize: '14px'}} component="th" scope="row">Order Date</TableCell>
                        <TableCell>{formatDateTime(value.orderDate)}</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                        <TableRow>
                          <TableCell sx={{ fontWeight: 600, fontSize: '14px'}} component="th" scope="row">Order Start Date</TableCell> 
                          <TableCell sx={{ fontWeight: 600, fontSize: '14px'}} component="th" scope="row"></TableCell> 
                          <TableCell sx={{ fontWeight: 600, fontSize: '14px'}} component="th" scope="row"></TableCell>

                        </TableRow>
                        <TableRow>
                          <TableCell sx={{ fontWeight: 500, fontSize: '14px'}} component="th" scope="row">- Requested Start Date
                          </TableCell>
                            <TableCell sx={{ fontWeight: 500, fontSize: '14px'}} component="th" scope="row">
                              {formatDateTime(value.requestedStartDate)} 
                            </TableCell>
                                <TableCell >
                                <IconButton
                                  sx={{ padding: 0}}
                                  disabled
                                  onClick={() => handleModify(value.requestedStartDate)}
                                >
                                  <DynamicIcon iconName="EditOutlined" sx={{ fontSize: '18px'}} />
                                </IconButton >
                            </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{ fontWeight: 500, fontSize: '14px'}} component="th" scope="row">- Actual Start Date</TableCell>
                          <TableCell>{formatDateTime(value.startDate)}</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{ fontWeight: 600, fontSize: '14px'}} component="th" scope="row">Order End Date</TableCell> 
                          <TableCell sx={{ fontWeight: 600, fontSize: '14px'}} component="th" scope="row"></TableCell> 
                          <TableCell sx={{ fontWeight: 600, fontSize: '14px'}} component="th" scope="row"></TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell sx={{ fontWeight: 500, fontSize: '14px'}} component="th" scope="row">- Requested Completion Date</TableCell>
                        <TableCell>{formatDateTime(value.requestedCompletionDate)} </TableCell>
                        <TableCell>
                        <IconButton
                            sx={{ padding: 0}}
                            disabled
                            onClick={() => handleModify(value.requestedCompletionDate)}
                          >
                            <DynamicIcon iconName="EditOutlined" sx={{ fontSize: '18px'}} />
                          </IconButton >
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell  sx={{ fontWeight: 500, fontSize: '14px'}} component="th" scope="row">- Expected Completion Date</TableCell>
                        <TableCell>{formatDateTime(value.endDate)}</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell  sx={{ fontWeight: 500, fontSize: '14px'}} component="th" scope="row">- Actual Completion Date</TableCell>
                        <TableCell>{formatDateTime(value.requestedCompletionDate)}</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell sx={{ fontWeight: 600, fontSize: '14px'}} component="th" scope="row">Order Cancellation</TableCell> 
                          <TableCell sx={{ fontWeight: 600, fontSize: '14px'}} component="th" scope="row"></TableCell> 
                          <TableCell sx={{ fontWeight: 600, fontSize: '14px'}} component="th" scope="row"></TableCell>
                        </TableRow>
                      <TableRow>
                        <TableCell  sx={{ fontWeight: 500, fontSize: '14px'}} component="th" scope="row">- Requested Cancellation Date</TableCell>
                        <TableCell>{formatDateTime(value.cancellationDate)}</TableCell>
                        <TableCell>
                        <IconButton
                            sx={{ padding: 0}}
                            disabled
                            onClick={() => handleModify(value.cancellationDate)}
                          >
                            <DynamicIcon iconName="EditOutlined" sx={{ fontSize: '18px'}} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ fontWeight: 500, fontSize: '14px'}} component="th" scope="row">- Cancellation Reason</TableCell>
                        <TableCell>{renderValue(value.cancellationReason)}</TableCell>
                        <TableCell>
                        <IconButton
                            sx={{ padding: 0}}
                            disabled
                            onClick={() => handleModify(value.cancellationReason)}
                          >
                            <DynamicIcon iconName="EditOutlined" sx={{ fontSize: '18px'}} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    
                  </TableBody>
                  )) }
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>

          <Accordion sx={{ borderRadius: 1}}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5">Related Services Instances</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer component={Paper} sx={{ width: '100%', borderRadius: 0 }}>
                <Table sx={{ tableLayout: 'fixed'}} size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow sx={{ backgroundColor: '#D3D3D3'}}>
                      <TableCell  sx={{ color: '#000', fontWeight: '600',fontSize: '14px'}}>Name</TableCell>
                      <TableCell  sx={{ color: '#000', fontWeight: '600',fontSize: '14px'}}>State</TableCell>
                      <TableCell  sx={{ color: '#000', fontWeight: '600',fontSize: '14px'}} scope="row">UUID</TableCell>
                      <TableCell  sx={{ color: '#000', fontWeight: '600',fontSize: '14px'}} scope="row"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {relatedInstances.length > 0 && relatedInstances.map((instance) => (
                      <TableRow key={instance.id}>
                        <TableCell >{instance.name}</TableCell>
                        <TableCell >{instance.state}</TableCell>
                        <TableCell >{instance.id}</TableCell>
                        <TableCell>
                          <IconButton
                            sx={{ padding: 0}}
                            >
                              <Link to={`/serviceinstance/${instance.id}`}>
                                <DynamicIcon iconName="Visibility" fontSize="small" sx={{ color: "#e20074" }} />
                              </Link>
                          </IconButton >
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>

          <Accordion sx={{ borderRadius: 1}}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5">Error Messages</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <TableContainer component={Paper} sx={{ width: '100%', borderRadius: 0 }}>
              <Table sx={{ tableLayout: 'fixed' }} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow sx={{ backgroundColor: '#D3D3D3'}}>
                    <TableCell  sx={{ color: '#000', fontWeight: '600',fontSize: '14px'}}></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Typography>No error messages found</Typography>
                    </TableCell>
                    </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            </AccordionDetails>
          </Accordion>
          <Grid container spacing={2} sx={{ mt:3, mb:3 }}>
            {/* Div 1: Update Service Order */}
            <Grid item xs={6} sx={{ textAlign: 'center' }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleUpdateServiceOrder}
                disabled
                size='large'
              >
                Update Service Order
              </Button>
            </Grid>

            {/* Div 2: Delete Service Order */}
            <Grid item xs={6} sx={{ textAlign: 'center' }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleDeleteServiceOrder}
                disabled
                size='large'
              >
                Delete Service Order
              </Button>
            </Grid>
          </Grid>
        </Box>
        </Box>
      ) : (
        <Typography>Loading...</Typography>
      )}
    {/* </Box> */}
    </Box>
  );
};

export default ServiceOrderDetails;
