import React from 'react';
import { Box } from '@mui/material';
import SideMenu from '../global/SideMenu';
import { useSelector } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import DashboardComponent from '../Dashboard/DashboardComponent'
import DigitalProvider from '../DigitalProvider'
import ServiceOrderComponent from '../serviceOrder/ServiceOrderComponent'
import ServiceOrderDetails from '../serviceOrder/ServiceOrderDetails'
import ServiceInstanceComponent from '../serviceInstances/ServiceInstanceComponent'
import ServiceInstancesDetails from '../serviceInstances/ServiceInstancesDetails'
import UserDetails from '../user/UserDetails'
import CreateNewServiceOrder from '../serviceOrder/CreateNewServiceOrder';
import StepByStepCheckoutForm from '../serviceOrder/createFormSteps/StepByStepCheckoutForm';
import ServiceCatalogComponent from '../catalog/ServiceCatalogComponent';
import ServiceCatalogDetails from '../catalog/ServiceCatalogDetails';
import UserDisabledPage from '../UserDisabledPage';
import AlarmMonitor from '../PerformanceMonitor';

const DashboardLayout = () => {
  const { userDetails, userError } = useSelector((state) => state.user || {});

  return (
    <Box sx={{ display: 'flex' }}>
      <SideMenu />

      <Box
        component="main"
        sx={{ 
          flexGrow: 1, 
          bgcolor: 'background.default', 
          pt: 3, 
          pb: 10, 
          maxWidth: '100%', 
          marginTop: '64px', 
          pl: 6, 
          pr: 8 
        }}
      >
        <Routes>
          {!(userDetails?.isDisabled || userError?.reason) ? (
            <>
              <Route path="/" element={<DashboardComponent />} />
              <Route path="/dashboard" element={<DashboardComponent />} />
              <Route path="/serviceorder" element={<ServiceOrderComponent />} />
              <Route path="/serviceorder/:id" element={<ServiceOrderDetails />} />
              <Route path="/serviceinstance" element={<ServiceInstanceComponent />} />
              <Route path="/serviceinstance/:id" element={<ServiceInstancesDetails />} />
              <Route path="/userDetails" element={<UserDetails />} />
              <Route path="/createNew" element={<CreateNewServiceOrder />} />
              <Route path="/createServiceForm" element={<StepByStepCheckoutForm />} />
              <Route path="/catalog" element={<ServiceCatalogComponent />} />
              <Route path="/catalog/:id" element={<ServiceCatalogDetails />} />
              <Route path="/digitalProvider" element={<DigitalProvider />} />
              <Route path="/performanceMonitor" element={<AlarmMonitor />} />
            </>
          ) : (
            <>
              <Route path="/*" element={<UserDisabledPage />} />
              <Route path="/dashboard" element={<UserDisabledPage />} />
            </>
          )}
        </Routes>
      </Box>
    </Box>
  );
};

export default DashboardLayout;