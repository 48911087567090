import React, { useState, useContext } from 'react';
import { AppContext } from '../../App';
import { Box, IconButton, Menu, Select, MenuItem, FormControl, Avatar, Typography, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import AccountCircle from '@mui/icons-material/AccountCircle';
import DynamicIcon from './DynamicIcon';

const AccountMenu = () => {
  const email = localStorage.getItem('userEmail');
  
  const { userDetails } = useSelector((state) => state.user || {});
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const { setIsAuthenticated } = useContext(AppContext);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('loginTimestamp');
    localStorage.removeItem('userEmail');
    setIsAuthenticated(false);
    navigate('/');
  };

  const handleAccountSetting = () => {
    navigate('/userDetails');
  };

  // Generate avatar initials if user details are present
  const avatarInitials = (userDetails && userDetails?.firstName && userDetails?.firstName.length > 0)
    ? `${userDetails?.firstName[0]}${userDetails?.lastName[0]}`
    : <AccountCircle />;

  // const avatarInitials = 'N/A';

  return (
    <>
      <Box sx={{ display: 'flex', gap: '10px' }} >
        {userDetails && userDetails.firstName ? (
          <IconButton
            edge="end"
            color="inherit"
            aria-label="account of current user"
            aria-controls="account-menu"
            aria-haspopup="true"
            onClick={handleClick}>
            <Avatar sx={{ bgcolor: '#7c7c7c', cursor: 'pointer' }}>
              {avatarInitials}
            </Avatar>
          </IconButton>
        ) : (
          <IconButton
            edge="end"
            color="inherit"
            aria-label="account of current user"
            aria-controls="account-menu"
            aria-haspopup="true"
            onClick={handleClick}>
            <Avatar sx={{ bgcolor: '#7c7c7c', cursor: 'pointer' }}>
              <AccountCircle />
            </Avatar>
          </IconButton>
        )}

        <FormControl variant="outlined" size="small">
          <Select
            defaultValue="en"
            sx={{ color: "black", marginTop: "0.5rem", fontSize: 14, backgroundColor: 'white' }}
            onChange={(e) => changeLanguage(e.target.value)}
          >
            <MenuItem selected value="en">EN</MenuItem>
            <MenuItem disabled value="de">DE</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Menu
        id="account-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 2,
          sx: {
            width: 230,
            mt: '44px',
            ml: '50px',
          },
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        
          <Box>
            <MenuItem sx={{ pointerEvents: 'none' }}>
              <Box component="div" sx={{ whiteSpace: 'normal' }}>
              {userDetails && userDetails.firstName && ( <Typography variant='body2'>
                  <strong>{`${userDetails?.lastName}, ${userDetails?.firstName}`}</strong>
                </Typography>
                  )}
                {userDetails && userDetails?.email ? (
                      <Typography variant='body2' component="p">{userDetails?.email }</Typography>
                  ) : (
                    <Typography variant='body2' component="p">{email}</Typography>
                  )}
              </Box>
            </MenuItem>
            <Divider />
            <MenuItem disabled={userDetails?.isDisabled} onClick={handleAccountSetting}>
              <DynamicIcon iconName="AccountBox" fontSize="small" sx={{ mr: 1 }} /> Account Settings
            </MenuItem>
          </Box>
        <MenuItem onClick={handleLogout}>
          <DynamicIcon iconName="Logout" fontSize="small" sx={{ mr: 1 }} />
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export default AccountMenu;
