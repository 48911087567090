import React from 'react';
import { Typography, Box, Button } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Link, useNavigate } from 'react-router-dom';

const OrderSuccess = ({setFormData, initialFormData , setActiveStep, responseorderID}) => {
    const navigate = useNavigate();
    
    const NewOrderState = () => {
        setActiveStep(0);
        setFormData(initialFormData);
        navigate('/createNew')
    }
  return (
    <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="400px"
        border={1}
        borderColor="#D3D3D3"
        borderRadius={1}
        p={4}
    >
        <CheckCircleIcon color="success" style={{ fontSize: 60,  mb:1 }} />
        <Typography variant="h4" color="info" gutterBottom >
            Service Order Created Successfully!
        </Typography>
        {responseorderID && (
        <Typography variant="body1" color="secondary" gutterBottom sx={{ border: 1, fontWeight: 500, borderRadius: 1, p:1}}>
            <Link to={`/serviceorder/${responseorderID}`} style={{ color: 'inherit', textDecoration: 'none' }}>
                Your Service Order id is {responseorderID}
            </Link>
        </Typography>
        )}
        
        <Button
            variant="outlined"
            size="large"
            sx={{ mt: 2 }}
            onClick={() => NewOrderState()}
        >
            Create New Order
        </Button>
    </Box>
  )
};

export default OrderSuccess;