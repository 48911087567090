import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../lib/axios';

// Async Thunk to fetch user roles and permissions
export const fetchUserRolesAndPermissions = createAsyncThunk(
  'user/fetchUserRolesAndPermissions',
  async (email, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`rbac/userRoleAndPermission/${email}`);
      return response.data;
    } catch (error) {
      // console.log(error.response?.data, 'err');

      // let jsonString = error.response?.data.replace(/'/g, '"');
      // let errorObject = JSON.parse(jsonString);
      return rejectWithValue(error.response?.data);
    }
  }
);

const initialState = {
  userDetails: {},
  organizations: [],
  status: 'idle',
  userError: null,
  projectCount: 0,
  projectsForCatalog: [],
  organizationCount: 0,
  userRole: '',
};

// Helper function to extract roles and organization info
const extractOrganizations = (orgData) => {
  return orgData.flatMap((org) =>
    org.csrProjects.flatMap((project) =>
      project.roles.map((role) => ({
        organizationName: org.csrProjectGroupName,
        projectName: project.projectName,
        roleName: role.roleName,
        projectState: project.isDisabled,
        roleState: role.isDisabled,
        isAdminProject: project.isAdmin,
        isAdminRole: role.isAdmin,
        isDisabledOrg: org.isDisabled
      }))
    )
  );
};

// Helper function to extract project names for catalog
const extractProjectsForCatalog = (orgData) => {
  return orgData.flatMap((org) =>
    (org.csrProjects || [])
      .filter((project) => project.isDisabled !== 1)
      .map((project) => project.projectName)
  );
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // You can define additional synchronous reducers here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserRolesAndPermissions.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserRolesAndPermissions.fulfilled, (state, action) => {

        if (action.payload?.statusCode === 404 || action.payload?.statusCode === 401 || action.payload?.statusCode === 403) {
          const error = {
            ...action.payload?.error,
            severity: "warning"
          }
          state.userError = error || { message: 'Failed to fetch service orders' };
        } else if (action.payload?.body && action.payload?.body.length === 0) {
          state.userError = {
            message: "No data found",
            reason: "Item Not Found"
          };
        } else {
          state.userError = null;
          state.status = 'succeeded';
          const userData = action.payload?.body;
          state.userDetails = userData;
          const csrProjectGroups = userData?.csrProjectGroups || [];
          // Extract roles and csrProjects info
          state.organizations = extractOrganizations(csrProjectGroups);
          
          const userRole = csrProjectGroups
            .flatMap(org => org.csrProjects)
            .flatMap(project => project.roles)
            .find(role => role.permissions.some(
              permission => permission.action === 'POST' && permission.url === 'ServiceOrder/{id}'
            ));
          userRole ? state.userRole = 'Admin' : state.userRole = 'viewer'          
          state.projectsForCatalog = extractProjectsForCatalog(csrProjectGroups);
          // Count csrProjectGroups and csrProjects
          state.organizationCount = state.organizations.length || 0;
          state.projectCount = csrProjectGroups.reduce(
            (total, org) => total + (org?.csrProjects?.length || 0),
            0
          );
        }
      })
      .addCase(fetchUserRolesAndPermissions.rejected, (state, action) => {
        state.status = 'failed';
        state.userError = { ...action.payload?.error,
          severity: "error"} || {
          message: "No data found",
          reason: "Item Not Found",
          severity: "error"};
      });
  },
});

export default userSlice.reducer;
