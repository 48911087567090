import React from 'react';
import * as Icons from '@mui/icons-material';

const DynamicIcon = ({ iconName, ...props }) => {
  const IconComponent = Icons[iconName];

  // If the icon does not exist, return null or a default icon
  if (!IconComponent) {
    return <Icons.HelpOutline {...props} />;
  }

  return <IconComponent {...props} />;
};

export default DynamicIcon;
