import React, { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchServiceOrders, setInitialServiceOrderTotalCount } from '../../store/serviceOrderSlice';
import { Box, Button, Typography, IconButton, CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import DataTable from '../global/DataTable';
import DynamicIcon from '../global/DynamicIcon';
import AlertComponent from '../global/AlertComponent';

const useTableColumns = () => {
  return useMemo(() => [
    { 
      accessorKey: 'category', 
      header: 'Category', 
      filterable: true, 
      sortable: true, 
      minSize: 15, 
      size: 20,
      filterFn: 'contains',
    },
    { 
      accessorKey: 'id', 
      header: 'UUID', 
      filterable: true, 
      minSize: 7, 
      size: 15,
      filterFn: 'contains',
    },
    { 
      accessorKey: 'description', 
      header: 'Description', 
      filterable: true, 
      sortable: true, 
      minSize: 10, 
      size: 30,
      filterFn: 'contains',
    },
    { 
      accessorKey: 'notificationContact', 
      header: 'Notification Contact', 
      filterable: true, 
      sortable: true, 
      minSize: 10, 
      size: 20,
      filterFn: 'contains',
    },
    { 
      accessorKey: 'state', 
      header: 'State', 
      filterable: true, 
      minSize: 10, 
      size: 20,
      filterFn: 'contains',
      Cell: ({ row }) => (
        <Typography variant='body2'>
          {row.original.state.replace(/([a-z])([A-Z])/g, "$1 $2").replace(/^\w/, (c) => c.toUpperCase())}
        </Typography>
      ),
    },
    { 
      accessorKey: 'externalId', 
      header: 'External ID', 
      filterable: true, 
      sortable: true, 
      minSize: 10, 
      size: 20,
      filterFn: 'contains', 
    },
    {
      accessorKey: 'requestedStartDate',
      header: 'Requested Start Date',
      filterable: true,
      sortable: true,
      minSize: 10,
      size: 20,
      filterFn: 'contains',
      Cell: ({ row }) => (
        <Typography variant='body1'>
          {format(new Date(row._valuesCache.requestedStartDate), 'yyyy-MM-dd')}
        </Typography>
      ),
    },
    {
      accessorKey: 'details',
      header: 'Details',
      filterable: false,
      sortable: false,
      minSize: 5,
      size: 15,
      filterFn: 'contains',
      enableColumnFilter: false,
      enableColumnActions: false,
      Cell: ({ row }) => (
        <IconButton sx={{ pb:0, pt: '1px'}}>
          <Link to={`/serviceorder/${row.id}`}> 
            <DynamicIcon iconName="Visibility" fontSize="medium" sx={{ color: "#e20074", fontSize: '20px' }} />
          </Link>
        </IconButton>
      ),
    },
    {
      accessorKey: 'modify',
      header: 'Modify',
      sortable: false,
      minSize: 10,
      size: 15,
      enableColumnFilter: false,
      enableColumnActions: false,
      Cell: () => (
        <IconButton disabled>
          <DynamicIcon iconName="EditOutlined" fontSize="medium" sx={{ fontSize: '20px'}} />
        </IconButton>
      ),
      filterFn: 'contains',
    },
    {
      accessorKey: 'delete',
      header: 'Delete',
      sortable: false,
      minSize: 10,
      size: 15,
      enableColumnFilter: false,
      enableColumnActions: false,
      Cell: () => (
        <IconButton disabled>
          <DynamicIcon iconName="DeleteOutlined" fontSize="medium" sx={{ fontSize: '20px'}} />
        </IconButton>
      ),
      filterFn: 'contains',
    },
  ], []);
};

const ServiceOrderComponent = () => {
  const isMounted = useRef(false);
  const dispatch = useDispatch();
  const { userRole } = useSelector((state) => state.user);
  const { serviceOrderList = [], error, loading } = useSelector((state) => state.serviceOrder || {});
  // Pagination state
  // const [pageIndex, setPageIndex] = useState(0); // Current page index
  // const [pageSize, setPageSize] = useState(10); // Rows per page

  const columns = useTableColumns();

  useEffect(() => {
    const fetchData = (isInitial = false) => {
      if (isInitial && !isMounted.current) {
        console.log("Component mounted - initial fetch");
        isMounted.current = true;
        dispatch(setInitialServiceOrderTotalCount());
        dispatch(fetchServiceOrders({ limit: 150, offset: 0 }));
      } else if (!isInitial) {
        console.log("Fetching data at regular interval...");
        dispatch(setInitialServiceOrderTotalCount());
        dispatch(fetchServiceOrders({ limit: 150, offset: 0 }));
      }
    };
  
    // Parse the interval from environment variables or use a default value
    const timer = Number(process.env.REACT_APP_FETCHDATA_INTERVAL) || 300000; // Default to 5 minutes (300,000ms)
  
    // Initial API call
    fetchData(true);

    // Set up interval for subsequent API calls
    const interval = setInterval(() => {
      fetchData(false);
    }, timer);
  
    // Cleanup interval on component unmount
    return () => {
      clearInterval(interval);
        console.log("Interval cleared on unmount.");
    };
  }, [dispatch]);  

  // const handlePaginationChange = (newPageIndex, newPageSize) => {
  //   setPageIndex(newPageIndex);
  //   setPageSize(newPageSize);
  //   console.log(newPageIndex * 10, 'newPageIndex * 10');
  //   dispatch(fetchServiceOrders({ limit: 10, offset: newPageIndex * 10 || 0}));
  // };

  // const handleNextPage = () => {
  //   if (pageIndex <= totalServiceOrders) {
  //     handlePaginationChange(pageIndex + 1, pageSize);
  //   }
  // };

  // const handlePrevPage = () => {
  //   if (pageIndex > 0) {
  //     handlePaginationChange(pageIndex - 1, pageSize);
  //   }
  // };

  const renderContent = () => {
    if (error?.reason) {
      return (
        <AlertComponent
          fullWidth
          severity={error.severity}
          title={error.reason}
          description={error.message}
        />
      );
    }

    if (loading) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', height: "500px", alignItems: 'center'  }}>
          <CircularProgress color="secondary" />
        </Box>
      );
    }

    return (
      <Box>
        <DataTable
          columns={columns}
          data={serviceOrderList}
          enableStickyHeader
          getRowId={(row) => row.id}
          showDownloadButton
          showPrintButton
          // limit={limit}
          // offset={offset}
          enableExpandAll={false}
          enableTopToolbar={true}
          // hideFooterPagination
          // enablePagination={false}
          // enableBottomToolbar={false}
          // limit={pageSize} // Rows per page
          // offset={pageIndex * pageSize} // Offset calculation
          // hasMore={hasMore}
          // handlePaginationChange={handlePaginationChange}
        />
        {/* Add custom pagination buttons */}
        {/* <Box sx={{ display: "flex", justifyContent: "end", marginTop: "10px" }}>
          <Typography variant="body2" sx={{ marginTop: '4px', mr: 1}}>
            Row per page 10
            <IconButton sx={{ p: 0}} disabled onClick={handlePrevPage} variant="standard">
              <DynamicIcon iconName="ArrowDropDown"/>
            </IconButton>
          </Typography>
          <IconButton onClick={handlePrevPage} color="icons" disabled={pageIndex === 0} variant="standard">
            <DynamicIcon iconName="ArrowBackIos" sx={{ fontSize: '16px' }} />
          </IconButton>
          <Box sx={{ margin: "0 10px", display: "flex", alignItems: "center", fontSize: '14px', }}>
            {pageIndex * 10 + 1} - {pageIndex * 10 + 10}
          </Box>
          <IconButton
            color="icons"
            onClick={handleNextPage}
            disabled={!hasMore}
            variant="standard"
          >
            <DynamicIcon iconName="ArrowForwardIos" sx={{ fontSize: '16px' }} />
          </IconButton>
        </Box> */}
      </Box>
    );
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Box sx={{ boxShadow: 3, borderRadius: 2, p: 2, overflow: 'hidden' }}>
        <Typography variant="h5">Service Orders List</Typography>
        
        <Box sx={{ display: 'flex', justifyContent: 'start', my: 2, gap: 2 }}>
          <Button 
            disabled={userRole !== 'Admin'} 
            variant="contained" 
            size="medium" 
            color="secondary"
          >
            <Link to="/createNew" style={{ color: 'inherit', textDecoration: 'none' }}>
              Create New Service Order
            </Link>
          </Button>
        </Box>

        {renderContent()}
      </Box>
    </Box>
  );
};

export default ServiceOrderComponent;