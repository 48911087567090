import React from 'react';

const IframeDashboard = ({ url }) => {
  return (
    <iframe
      src={url}
      style={{
        width: '100%',
        height: '100%', // Adjust as needed
        border: 'none',
      }}
      title="External Dashboard"
      allowFullScreen
    />
  );
};

export default IframeDashboard;
