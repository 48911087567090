import React, { useState, useEffect, useCallback } from 'react';
import { Box, Button, Typography, Grid, CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchServiceOrders, createNewServiceOrder } from '../../../store/serviceOrderSlice';
import { fetchServiceInstances } from '../../../store/serviceInstanceSlice';
import { fetchServiceCatalogs } from '../../../store/serviceCatalogSlice';
import DynamicIcon from '../../global/DynamicIcon';
import { setFormData, setActiveStep, resetFormData, getService } from '../../../store/createNewServiceOrder';
import VerifyOrder from './VerifyOrder';
import StepsComponent from './StepsComponent';
import AddServices from './AddServices';
import OrderSuccess from './OrderSuccess';
import CreateServiceOrderStep from './CreateServiceOrderStep';
import AlertComponent from '../../global/AlertComponent'
import { useNavigate, useLocation } from 'react-router-dom';
import { useServiceOrderValidation } from '../../../hooks/useServiceValidation';

const steps = ['Initiate Service Order', 'Specify Services', 'Verify Order', 'Submitting Order', 'Order Complete'];

const StepByStepCheckoutForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { enabledCatalogs = [] } = useSelector((state) => state.serviceCatalog || {});
  const [stateLoading, setStateLoading] = useState(false);
  const { formData, activeStep, checkFormType } = useSelector((state) => state.createNewServiceOrder);
  const { createNewOrder = {}, createError } = useSelector((state) => state.serviceOrder || {});
  const categoryValue = formData.category;
  const serviceTypeValue = useSelector((state) => getService(state, 'serviceType'));
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [responseorderID, setResponseorderID] = useState('');
  const [intraZVLanError, setIntra_zVLanError] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(categoryValue);
  const [filteredServiceTypes, setFilteredServiceTypes] = useState([]);
  const [selectedServiceType, setSelectedServiceType] = useState(serviceTypeValue);

  useEffect(() => {
    // Check if the URL contains "createServiceForm"
    if (location.state?.from !== 'catalog') {
      dispatch(resetFormData()); // Reset the store
      dispatch(setActiveStep(0));
    }
  }, [location.state, dispatch]);
  
  useEffect(() => {
    dispatch(fetchServiceCatalogs());
  }, [dispatch]);

  useEffect(() => {
    if (selectedCategory) {
      const filteredTypes = [...new Set(
        enabledCatalogs
          .filter(service => service.category === selectedCategory)
          .map(service => service.service_type)
      )];
      setFilteredServiceTypes(filteredTypes);
    } else {
      setFilteredServiceTypes([]); // Clear service types if no category is selected
    }
  }, [selectedCategory, enabledCatalogs]);

  // Clean form data utility
  const cleanFormData = useCallback((data) => {
    if (!data) return {};

    const cleanedData = { ...data };

    if (cleanedData.serviceOrderItem) {
      cleanedData.serviceOrderItem = cleanedData.serviceOrderItem.map(item => {
        const serviceCopy = {
          ...item.service,
          serviceType: item.service.serviceType || undefined,
          name: item.service.name || undefined,
          serviceSpecification: {
            id: item.service.serviceSpecification?.id || undefined
          },
          serviceCharacteristic: (item.service.serviceCharacteristic || [])
            .map(char => ({ ...char }))
            .filter(char => char.value !== "" && char.value !== 0)
        };

        return {
          ...item,
          service: serviceCopy
        };
      });
    }

    return cleanedData;
  }, []);

  // Clean form data on step change
  useEffect(() => {
    if (activeStep === 2) {
      const result = cleanFormData(formData);
      if (JSON.stringify(result) !== JSON.stringify(formData)) {
        // dispatch(setFormData(result));
        dispatch(setFormData({
          ...result,
          requestedStartDate: new Date(Date.now() + 60 * 60 * 1000).toISOString(),
        }))
      }
    }
  }, [activeStep, formData, cleanFormData, dispatch]);

  const handleNext = () => {
    if (!isNextDisabled) {
      // setActiveStep((prevActiveStep) => prevActiveStep + 1);
      dispatch(setActiveStep(activeStep + 1));
    }
  }
  const handleBack = () => {
    if(checkFormType === 'wizard') {
      dispatch(setActiveStep(activeStep - 1))
    } else {
      dispatch(setActiveStep(0));
      dispatch(resetFormData());
      navigate('/createNew');
    }
  };

  const handleExitForm = () => {
    dispatch(setActiveStep(0));
    dispatch(resetFormData());
    navigate('/createNew');
  };
  
  async function handleSubmit(event) {
    event.preventDefault();
    setStateLoading(true);
  
    try {
      // Prepare form data with updated start date
      handleNext();
      // Dispatch createNewServiceOrder and wait for the result
      const resultAction = await dispatch(createNewServiceOrder(formData));
  
      if (createNewServiceOrder.fulfilled.match(resultAction)) {
        const { payload } = resultAction;
  
        if (payload.statusCode === 200) {
          const orderID = payload.body?.[0]?.id;
  
          setResponseorderID(orderID);
          dispatch(setActiveStep(4));
          dispatch(fetchServiceOrders());
          dispatch(fetchServiceInstances());
        } else {
          console.warn('Unexpected status code:', payload.statusCode);
        }
      } else if (createNewServiceOrder.rejected.match(resultAction)) {
        console.error('Order creation failed:', resultAction.error);
      }
    } catch (error) {
      console.error('Error during handleSubmit:', error);
    } finally {
      setStateLoading(false);
    }
  }
  

  const isNextDisabled = useServiceOrderValidation(formData, activeStep);

  return (
    <Box>
      <Grid
        container
        spacing={3}
      >
        {/* Back Button */}
        <Grid item xs={9}>
          <Button
            sx={{ mb: 2 }}
            onClick={handleExitForm}
            variant="outlined"
            color="secondary"
          >
            <DynamicIcon iconName="ArrowBack" fontSize="medium" />
          </Button>
        </Grid>

        {/* Form */}
      </Grid>
      <Box sx={{maxWidth: '1024px', m: 'auto'}}>
        <StepsComponent
          steps={steps}
          activeStep={activeStep}
        />
        <Box component="form"
          sx={{
            boxShadow: activeStep === 2 ? null : 3,
            borderRadius: activeStep === 2 ? null : 2,
            padding: activeStep === 2 ? null : 2
          }}
          onSubmit={handleSubmit}>
          {activeStep === 0 && (
            <CreateServiceOrderStep
              sx={{ boxShadow: 3, borderRadius: 2, padding: 2 }}
              selectedStartDate={selectedStartDate}
              selectedEndDate={selectedEndDate}
              setSelectedStartDate={setSelectedStartDate}
              setSelectedEndDate={setSelectedEndDate}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
            />
          )}

          {activeStep === 1 && (
            <AddServices
              intraZVLanError={intraZVLanError}
              setIntra_zVLanError={setIntra_zVLanError}
              filteredServiceTypes={filteredServiceTypes}
              selectedCategory={selectedCategory}
              selectedServiceType={selectedServiceType}
              setSelectedServiceType={setSelectedServiceType}
            // setFormData={setFormData}
            />
          )}

          {activeStep === 2 && (
            <Box sx={{ pt: 2 }}>
              <VerifyOrder formData={formData} />
            </Box>
          )}

          {activeStep === 3 && (
            <Box
              minHeight="300px"
              boxShadow={0}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center'
              }}
            >

              {stateLoading && (
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <CircularProgress color="secondary" />
                  <Typography
                    variant="plain"
                    startDecorator={<CircularProgress />}
                    sx={{ p: 1 }}
                  >
                    Submitting...
                  </Typography>
                </Box>
              )}
              {createNewOrder && createNewOrder.errorMessage && (
                <AlertComponent
                  fullWidth
                  severity="error"
                  title={createNewOrder.errorMessage}
                  description={createNewOrder.errorType}
                />
              )}
              {createError && createError.message && (
                <AlertComponent
                  fullWidth
                  severity="error"
                  title={createError.reason}
                  description={createError.message}
                />
              )}
              {
                createNewOrder && createNewOrder.error && createNewOrder.error.message && (
                  <AlertComponent
                    fullWidth
                    severity="error"
                    title={createNewOrder.error.reason}
                    description={createNewOrder.error.message}
                  />
                )
              }
            </Box>
          )}
          {activeStep === 4 && (
            <OrderSuccess responseorderID={responseorderID} setActiveStep={setActiveStep} setFormData={setFormData} initialFormData={formData} />
          )}

          {
            (activeStep !== 4 && !stateLoading) && <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
              <Button variant="outlined" color="secondary" disabled={activeStep === 0} onClick={handleBack}>
                Back
              </Button>
              {activeStep === steps.length - 3 ? (
                <Button variant="contained" color="secondary" onClick={handleSubmit}>
                  Submit
                </Button>
              ) : (
                <Button variant="contained" disabled={isNextDisabled} color="secondary" onClick={handleNext}>
                  Next
                </Button>
              )}
            </Box>
          }
        </Box>
      </Box>
    </Box>

  );
};

export default StepByStepCheckoutForm;
