import React from "react";
import RadioUnChecked from '@mui/icons-material/RadioButtonUnchecked';
import RadioChecked from '@mui/icons-material/RadioButtonChecked';
import MaterialReactTable, {
  MRT_GlobalFilterTextField as MRTGlobalFilterTextField,
  MRT_ShowHideColumnsButton as MRTShowHideColumnsButton,
  MRT_ToggleFiltersButton as MRTToggleFiltersButton,
  MRT_ToggleGlobalFilterButton as MRTToggleGlobalFilterButton,
  MRT_ToolbarAlertBanner as MRTToolbarAlertBanner
} from 'material-react-table';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Box } from "@mui/material";

export function CSCheckBoxIcon(condition) {
  return condition ? <RadioChecked fontSize="small" /> : <RadioUnChecked fontSize="small" />;
}

const DataTable = (props) => {
  const theme = createTheme({
    palette: {
      magenta: {
        main: '#e20074'
      }
    },
  });

  // Custom global filter function
  const globalFilterFn = (row, columnId, filterValue) => {
    if (!row || typeof row.getValue !== 'function') {
      return false; // Return false if row or getValue is not defined
    }
  
    const value = row.getValue(columnId) || ""; // Default to empty string if no value
    return value.toString().toLowerCase().includes(filterValue.toLowerCase());
  };

  return (
    <ThemeProvider theme={theme}>
      <MaterialReactTable
        {...props}
        enableFullScreenToggle={false}
        enableColumnResizing={true}
        columnResizeDirection='rtl'
        enableDensityToggle={true}
        initialState={{
          ...props.initialState,
          density: 'compact',
        }}
        muiTablePaperProps={{
          elevation: 0,
        }}
        muiTableHeadCellProps={{
          ...props.muiTableHeadCellProps,
          sx: {
            fontWeight: '600',
            color: 'black',
            justifyContent: 'space-between',
            alignItems: 'row-reverse',
            fontSize: '14px',
            paddingRight: 0,
            backgroundColor: '#D3D3D3',
            '& .Mui-TableHeadCell-Content': {
              justifyContent: 'space-between',
            },
          },
        }}
        muiSortLabelProps={{
          sx: {
            color: 'black',  // Apply custom color
            '&:hover': {
              color: 'secondary.main', // Change color on hover
            },
            '&.Mui-active': {
              color: '#d500f9', // Color when sorting is active
            },
          },
        }}
        muiTableBodyCellProps={{
          sx: {
            fontSize: '14px',
            paddingLeft: '2px',
          },
        }}
        state={{ ...props.state }}
        isTopToolbar={true}
        muiLinearProgressProps={({ isTopToolbar }) => ({
          color: 'magenta',
          sx: { display: isTopToolbar ? 'block' : 'none' }
        })}
        globalFilterFn={globalFilterFn} // Use the custom global filter function
        enableGlobalFilter={true}
        renderTopToolbar={({ table }) => (
          <>
            <Box sx={{ display: props.positionToolbarAlertBanner === "top" ? "block" : "none" }}>
              <MRTToolbarAlertBanner stackAlertBanner table={table} />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Box sx={{ display: "flex", justifyContent: "flex-start", margin: "10px" }}>
                {props.tableTitleComponent}
              </Box>

              {/* Conditionally render Global Filtering */}
              {table.getRowModel().rows.length > 0 && (
                <>
                  <MRTGlobalFilterTextField table={table} />
                  <Box sx={{ display: "flex", gap: "10px" }}>
                    <MRTToggleGlobalFilterButton table={table} />
                    <MRTToggleFiltersButton table={table} />
                    <MRTShowHideColumnsButton table={table} />
                  </Box>
                </>
              )}
            </Box>
          </>
        )}
      />
    </ThemeProvider>
  );
};

export default DataTable;

// Paginated Code:
// import React from "react";
// import RadioUnChecked from "@mui/icons-material/RadioButtonUnchecked";
// import RadioChecked from "@mui/icons-material/RadioButtonChecked";
// import MaterialReactTable, {
//   MRT_GlobalFilterTextField as MRTGlobalFilterTextField,
//   MRT_ShowHideColumnsButton as MRTShowHideColumnsButton,
//   MRT_ToggleFiltersButton as MRTToggleFiltersButton,
//   MRT_ToggleGlobalFilterButton as MRTToggleGlobalFilterButton,
//   MRT_ToolbarAlertBanner as MRTToolbarAlertBanner,
// } from "material-react-table";
// import { createTheme, ThemeProvider } from "@mui/material/styles";
// import { Box } from "@mui/material";

// export function CSCheckBoxIcon(condition) {
//   return condition ? <RadioChecked fontSize="small" /> : <RadioUnChecked fontSize="small" />;
// }

// const DataTable = ({
//   limit: initialLimit,
//   offset: initialOffset,
//   onPaginationChange,
//   rowCount,
//   hasMore,
//   handlePaginationChange,
//   ...props
// }) => {
//   const theme = createTheme({
//     palette: {
//       magenta: {
//         main: "#e20074",
//       },
//       icons: {
//         main: "#000000"
//       }
//     },
//   });

//   // // Pagination state
//   // const pageIndex = initialOffset / initialLimit || 0; // Calculate initial page index
//   // const pageSize = initialLimit || 10;

//   return (
//     <ThemeProvider theme={theme}>
//       <MaterialReactTable
//         {...props}
//         enableFullScreenToggle={false}
//         enableColumnResizing={true}
//         columnResizeDirection="rtl"
//         enableDensityToggle={true}
//         // muiPaginationProps={{
//         //   showFirstButton: false,
//         //   showLastButton: false,
//         //   page: pageIndex, // Sync current page index
//         //   rowsPerPage: 5, // Sync rows per page
//         //   onPageChange: (event, newPageIndex) => handlePaginationChange(newPageIndex, pageSize), // Handle page navigation
//         //   onRowsPerPageChange: (event) => handlePaginationChange(0, Number(event.target.value)), // Reset to page 0 when page size changes
//         // }}
//         initialState={{
//           ...props.initialState,
//           density: "compact",
//           // pagination: { pageIndex, pageSize },
//         }}
//         muiTablePaperProps={{
//           elevation: 0,
//         }}
//         muiTableHeadCellProps={{
//           ...props.muiTableHeadCellProps,
//           sx: {
//             fontWeight: "600",
//             color: "black",
//             justifyContent: "space-between",
//             alignItems: "row-reverse",
//             fontSize: "14px",
//             backgroundColor: "#D3D3D3",
//             "& .Mui-TableHeadCell-Content": {
//               justifyContent: "space-between",
//             },
//           },
//         }}
//         muiSortLabelProps={{
//           sx: {
//             color: "black",
//             "&:hover": {
//               color: "secondary.main", // Change color on hover
//             },
//             "&.Mui-active": {
//               color: "#d500f9", // Color when sorting is active
//             },
//           },
//         }}
//         muiTableBodyCellProps={{
//           sx: {
//             fontSize: "14px",
//             paddingLeft: "2px",
//           },
//         }}
//         manualPagination // Enable manual pagination
//         rowCount={rowCount} // Total record count for pagination
//         // onPaginationChange={() => handlePaginationChange(pageIndex, pageSize)}
//         isTopToolbar={true}
//         muiLinearProgressProps={({ isTopToolbar }) => ({
//           color: "magenta",
//           sx: { display: isTopToolbar ? "block" : "none" },
//         })}
//         globalFilterFn="contains"
//         renderTopToolbar={({ table }) => (
//           <>
//             <Box sx={{ display: props.positionToolbarAlertBanner === "top" ? "block" : "none" }}>
//               <MRTToolbarAlertBanner stackAlertBanner table={table} />
//             </Box>
//             <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
//               <Box sx={{ display: "flex", justifyContent: "flex-start", margin: "10px" }}>
//                 {props.tableTitleComponent}
//               </Box>
//               <MRTGlobalFilterTextField table={table} />
//               <Box sx={{ display: "flex", gap: "10px" }}>
//                 <MRTToggleGlobalFilterButton table={table} />
//                 <MRTToggleFiltersButton table={table} />
//                 <MRTShowHideColumnsButton table={table} />
//               </Box>
//             </Box>
//           </>
//         )}
//       />
//       {/* Add custom pagination buttons */}
//       {/* <Box sx={{ display: "fle x", justifyContent: "end", marginTop: "10px" }}>
//         <Typography variant="body2" sx={{ marginTop: '5px', mr: 1}}>
//           Row per page 10
//           <IconButton sx={{ p: 0}} disabled onClick={handlePrevPage} variant="standard">
//             <DynamicIcon iconName="ArrowDropDown"/>
//           </IconButton>
//         </Typography>
//         <IconButton onClick={handlePrevPage} color="icons" disabled={pageIndex === 0} variant="standard">
//           <DynamicIcon iconName="ArrowBackIos" sx={{ fontSize: '16px' }} />
//         </IconButton>
//         <Box sx={{ margin: "0 10px", display: "flex", alignItems: "center", fontSize: '14px', }}>
//           {pageIndex + 1}-10 of {rowCount}
//         </Box>
//         <IconButton
//           color="icons"
//           onClick={handleNextPage}
//           disabled={!hasMore}
//           variant="standard"
//         >
//           <DynamicIcon iconName="ArrowForwardIos" sx={{ fontSize: '16px' }} />
//         </IconButton>
//       </Box> */}
//     </ThemeProvider>
//   );
// };

// export default DataTable;

