import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../lib/axios';

const statesToMatch = ['acknowledged', 'pending', 'inProgress', 'partial'];

// Helper to handle errors
const handleError = (error, rejectWithValue) => {
  if (!error.response) {
    throw new Error('Network error: Failed to connect to the server');
  }
  return rejectWithValue(error.response?.data || { message: 'An unknown error occurred' });
};

// Async thunk to fetch service orders with pagination
export const fetchServiceOrders = createAsyncThunk(
  'serviceOrder/fetchServiceOrders',
  async ({ limit, offset }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get('serviceorder/serviceOrder', {
        params: {
          limit,
          offset,
        },
      });
      // Return items and check if more data is available
      return response.data.body;
    } catch (error) {
      return handleError(error, rejectWithValue);
    }
  }
);

// Async thunk to fetch service order by ID
export const fetchServiceOrderByID = createAsyncThunk(
  'serviceOrder/fetchServiceOrderByID',
  async (selectedInstance, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/serviceorder/serviceOrder/${selectedInstance}`);
      if (response?.data?.statusCode === 403) {
        return rejectWithValue(response.data.error);
      }
      return response.data.body;
    } catch (error) {
      return handleError(error, rejectWithValue);
    }
  }
);

// Async thunk to create a new service order
export const createNewServiceOrder = createAsyncThunk(
  'serviceOrder/createNewServiceOrder',
  async (serviceOrderData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('serviceorder/serviceOrder', serviceOrderData);
      return response.data;
    } catch (error) {
      return handleError(error, rejectWithValue);
    }
  }
);

const initialState = {
  serviceOrderList: [],
  totalServiceOrders: 0,
  loading: false,
  serviceOrderCount: 0,
  createNewOrder: {},
  createLoading: false,
  error: null,
  createError: null,
  serviceOrderById: [],
  relatedInstances: [],
};

const serviceOrderSlice = createSlice({
  name: 'serviceOrder',
  initialState,
  reducers: {
    setInitialServiceOrderTotalCount: (state) => {
      state.totalServiceInstanceCount = 0;
    },
  },
  extraReducers: (builder) => {
    // Reusable function to handle errors
    const setErrorState = (state, action, field = 'error') => {
      state[field] = {
        ...action.payload?.error,
        severity: 'error',
      } || {
        message: 'An error occurred',
        severity: 'error',
      };
    };

    builder
      // Handle fetchServiceOrders
      .addCase(fetchServiceOrders.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchServiceOrders.fulfilled, (state, action) => {
        const { statusCode, body, error } = action.payload || {};

        if ([401, 403, 404].includes(statusCode)) {
          state.error = {
            ...error,
            severity: 'warning',
          } || { message: 'Failed to fetch service orders' };
        } else if (body?.length === 0) {
          state.error = {
            message: 'No data found',
            reason: 'Item Not Found',
            severity: 'warning',
          };
        } else {
          state.serviceOrderList = action.payload;
          state.totalServiceOrders += action.payload.length;
          state.serviceOrderCount = action.payload.filter((order) => statesToMatch.includes(order.state)).length || 0;
          state.error = null;
        }

        state.loading = false;
      })
      .addCase(fetchServiceOrders.rejected, (state, action) => {
        setErrorState(state, action);
        state.loading = false;
      })

      // Handle fetchServiceOrderByID
      .addCase(fetchServiceOrderByID.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchServiceOrderByID.fulfilled, (state, action) => {
        const [order] = action.payload || [];
        state.relatedInstances = order?.serviceOrderItem.map((item) => ({
          id: item.service?.id,
          name: item.service?.name || 'N/A',
          state: item.service?.state || 'N/A',
        })) || [];
        state.serviceOrderById = [order];
        
        state.loading = false;
      })
      .addCase(fetchServiceOrderByID.rejected, (state, action) => {
        setErrorState(state, action, 'serviceError');
        state.loading = false;
      })

      // Handle createNewServiceOrder
      .addCase(createNewServiceOrder.pending, (state) => {
        state.createLoading = true;
        state.createError = null;
      })
      .addCase(createNewServiceOrder.fulfilled, (state, action) => {
        state.createNewOrder = action.payload;
        state.createLoading = false;
      })
      .addCase(createNewServiceOrder.rejected, (state, action) => {
        setErrorState(state, action, 'createError');
        state.createLoading = false;
      });
  },
});

export const { setInitialServiceOrderTotalCount } = serviceOrderSlice.actions;
export default serviceOrderSlice.reducer;
