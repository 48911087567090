import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import DynamicIcon from './global/DynamicIcon';
import { useNavigate } from 'react-router-dom';
import IframeDashboard from './global/IframeDashboard';

const AlarmMonitor = () => {
  const navigate = useNavigate();
  const grafanaUrl = process.env.REACT_APP_GRAFANA_URL;
  return (
    <Box sx={{ pb:2 }}>
       <Button sx={{ mb: 2 }} onClick={() => navigate(-1)} variant="outlined" color="secondary">
        <DynamicIcon iconName="ArrowBack" sx={{ fontSize: '18px'}} />
      </Button>
      <Box sx={{ boxShadow: 3, borderRadius: 2, px: 1.5, overflow: 'hidden', height: '100vh', width: '100%', textAlign:'center' }}>
        <Typography variant="h5" marginY={2}>Performance Monitor</Typography>
        <IframeDashboard
          url={grafanaUrl}
          title="Grafana Dashboard"
          width="100%"
          height="100%"
          frameBorder="0"
        ></IframeDashboard>
      </Box>
    </Box>
  );
};

export default AlarmMonitor;
