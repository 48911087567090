import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete, Box, TextField, Typography, MenuItem, Grid, FormControl } from '@mui/material';
import CustomDatePicker from '../../global/DatePicker';
import { formatInTimeZone } from 'date-fns-tz';
import { setServiceSpecification, setServiceCharacteristic, updateServiceField, getServiceCharacteristics } from '../../../store/createNewServiceOrder';

const AddServices = ({
  intraZVLanError,
  setIntra_zVLanError,
  filteredServiceTypes,
  selectedCategory,
  selectedServiceType,
  setSelectedServiceType
}) => {
  const { formData, deploymentFlavour } = useSelector((state) => state.createNewServiceOrder);
  const { enabledCatalogs = [] } = useSelector((state) => state.serviceCatalog || {});
  const { projectsForCatalog = [] } = useSelector((state) => state.user || {});
  const infraServiceValue = useSelector((state) => getServiceCharacteristics(state, 'infraService'));
  const providerValue = useSelector((state) => getServiceCharacteristics(state, 'provider'));
  const dispatch = useDispatch();

  // Date states
  const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1))
    .toISOString().split('T')[0] + "T00:00:00.000Z";
  const [selectedServiceStartDate, setSelectedServiceStartDate] = useState(null);
  const [selectedServiceEndDate, setSelectedServiceEndDate] = useState(null);
  // const [initialServiceDate, setInitialServiceDate] = useState(tomorrow);
  const [initialServiceEndDate, setInitialServiceEndDate] = useState(null);

  // Service options states
  const [infraServiceOptions, setInfraServiceOptions] = useState([]);
  const [providerOptions, setProviderOptions] = useState([]);
  const [availableLocationOptions, setAvailableLocationOptions] = useState([]);
  const [deploymentFlavourOptions, setDeploymentFlavourOptions] = useState([]);
  const [projectOptions, setProjectOptions] = useState([]);

  // Selected states
  const [selectedInfraService, setSelectedInfraService] = useState(infraServiceValue);
  const [selectedProvider, setSelectedProvider] = useState(providerValue);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [selectedDeploymentFlavour, setSelectedDeploymentFlavour] = useState(deploymentFlavour);

  // Memoized functions
  const formatDateToUTC = useCallback((date, time) => {
    if (!date) return '';
    return formatInTimeZone(date, 'UTC', `yyyy-MM-dd'T'${time}.SSS'Z'`);
  }, []);

  const getServiceSpecificationID = useCallback((deploymentFlavour) => {
    const matchingDeliveryPoint = enabledCatalogs.find((item) =>
      item.nfSpecification.deploymentFlavour === deploymentFlavour
    );
    return matchingDeliveryPoint?.service_catalog_uuid || null;
  }, [enabledCatalogs]);

  const handleNestedChange = useCallback((event, field, nestedFieldName) => {
    const updatedServiceOrderItem = formData.serviceOrderItem.map((item) => ({
      ...item,
      service: {
        ...item.service,
        serviceCharacteristic: item.service.serviceCharacteristic.map((c) => ({ ...c })),
      },
    }));

    const value = event?.target ? event.target.value : event;

    if (nestedFieldName === 'serviceCharacteristic') {
      const characteristic = updatedServiceOrderItem[0].service.serviceCharacteristic
        .find((c) => c.name === field);
      if (characteristic) {
        characteristic.value = value;
      }
      dispatch(setServiceCharacteristic({ field, value }));
    } else if (nestedFieldName === 'serviceSpecification') {
      dispatch(setServiceSpecification({ value }));
    } else {
      dispatch(updateServiceField({ field, value }));
    }

    if (field === 'serviceType') {
      setSelectedServiceType(value);
    }
  }, [dispatch, formData.serviceOrderItem, setSelectedServiceType]);

  const handleDateChange = useCallback((date, field) => {
    const isoString = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
    const utcFormat = formatDateToUTC(isoString, field === 'startDate' ? '00:00:00' : '23:59:00');

    if (field === 'startDate') {
      setSelectedServiceStartDate(isoString);
    } else if (field === 'endDate') {
      setSelectedServiceEndDate(isoString);
    }

    dispatch(updateServiceField({ field, value: utcFormat }));
  }, [dispatch, formatDateToUTC]);

  // Effects
  useEffect(() => {
    if (selectedServiceType) {
      const filteredCatalogs = enabledCatalogs.filter(
        item => item.service_type === selectedServiceType
      );
      const uniqueInfraServices = [
        ...new Set(
          filteredCatalogs.flatMap(item =>
            item.deliveryPoint.map(dp => dp.infraService)
          )
        )
      ];

      setInfraServiceOptions(uniqueInfraServices);
    } else {
      const allInfraServices = [
        ...new Set(
          enabledCatalogs.flatMap(item =>
            item.deliveryPoint.map(dp => dp.infraService))
        )
      ];

      setInfraServiceOptions(allInfraServices);
    }
  }, [selectedServiceType, enabledCatalogs]);


  useEffect(() => {
    if (selectedInfraService) {
      handleNestedChange(selectedInfraService, 'infraService', 'serviceCharacteristic');
      const providers = [...new Set(
        enabledCatalogs
          .flatMap(item => item.deliveryPoint)
          .filter(dp => dp.infraService === selectedInfraService)
          .map(dp => dp.provider)
      )];
      setProviderOptions(providers);
    } else {
      setProviderOptions([]);
    }
  }, [selectedInfraService, enabledCatalogs, handleNestedChange]);

  useEffect(() => {
    if (!selectedProvider) {
      setAvailableLocationOptions([]);
      return;
    }

    handleNestedChange(selectedProvider, 'provider', 'serviceCharacteristic');

    const extractAvailableLocations = () => {
      const locations = enabledCatalogs
        .flatMap(catalog =>
          catalog.deliveryPoint.filter(dp =>
            dp.provider === selectedProvider &&
            dp.infraService === selectedInfraService
          ).flatMap(dp => dp.availableLocation.split(','))
        );

      return Array.from(new Set(locations)); // Remove duplicates
    };

    const uniqueLocations = extractAvailableLocations();
    setAvailableLocationOptions(uniqueLocations);
  }, [selectedProvider, selectedInfraService, enabledCatalogs, handleNestedChange]);


  useEffect(() => {
    if (selectedProvider && selectedLocation) {
      handleNestedChange(selectedLocation, 'location', 'serviceCharacteristic');
      const flavours = [...new Set(
        enabledCatalogs
          .filter(item =>
            item.deliveryPoint.some(dp =>
              dp.provider === selectedProvider &&
              dp.availableLocation.split(',').includes(selectedLocation)
            )
          )
          .map(item => item.nfSpecification.deploymentFlavour)
      )].sort();
      setDeploymentFlavourOptions(flavours);
    } else {
      setDeploymentFlavourOptions([]);
    }
  }, [selectedProvider, selectedLocation, enabledCatalogs, handleNestedChange]);

  useEffect(() => {
    if (selectedDeploymentFlavour) {
      const specId = getServiceSpecificationID(selectedDeploymentFlavour);
      handleNestedChange(specId, 'id', 'serviceSpecification');
    }
  }, [selectedDeploymentFlavour, getServiceSpecificationID, handleNestedChange]);

  useEffect(() => {
    if (getServiceSpecificationID(selectedDeploymentFlavour) || selectedServiceStartDate) {
      const catalogId = getServiceSpecificationID(selectedDeploymentFlavour);
      const matchingDeliveryPoint = enabledCatalogs.find(
        (item) => item.service_catalog_uuid === catalogId
      );

      if (!matchingDeliveryPoint) return;

      const serviceLifetimeInDays = matchingDeliveryPoint.minimum_service_lifetime / 1440;
      let serviceStartDate = selectedServiceStartDate ?
        new Date(selectedServiceStartDate) :
        new Date(tomorrow);

      serviceStartDate.setDate(serviceStartDate.getDate() + 1);
      const calculatedEndDate = new Date(
        serviceStartDate.setDate(serviceStartDate.getDate() + 1 + serviceLifetimeInDays)
      );
      const formattedEndDate = formatDateToUTC(calculatedEndDate, '23:59:00');

      setInitialServiceEndDate(formattedEndDate);
      dispatch(updateServiceField({ field: 'endDate', value: formattedEndDate }));
    }
  }, [
    selectedDeploymentFlavour,
    enabledCatalogs,
    selectedServiceStartDate,
    tomorrow,
    dispatch,
    getServiceSpecificationID,
    formatDateToUTC
  ]);

  useEffect(() => {
    setProjectOptions(projectsForCatalog?.length ?
      projectsForCatalog :
      [{ label: "No Projects Found", value: '' }]
    );
  }, [projectsForCatalog]);

  return (
    <Box elevation={2} noValidate>
      {formData.serviceOrderItem.map((item, index) => (
        <Box key={index}>
          <Typography variant="h6" sx={{ mb: 1 }}>Service Details</Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth sx={{ mb: 2 }} disabled={!selectedCategory}>
                <TextField
                  select
                  required
                  label="Service Type"
                  name="serviceType"
                  fullWidth
                  value={selectedServiceType}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => handleNestedChange(e, 'serviceType', '')}
                // onChange={e => setSelectedServiceType(e.target.value)}
                >
                  {filteredServiceTypes && filteredServiceTypes.length > 0 ? (
                    filteredServiceTypes.map((serviceType) => (
                      <MenuItem key={serviceType} value={serviceType}>
                        {serviceType}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="">No service type options found</MenuItem>
                  )}
                </TextField>
              </FormControl>

            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                label="Service Name"
                name="name"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={item.service.name}
                onChange={(e) => handleNestedChange(e, 'name', '')}
                placeholder='Friendly name for the service (e.g. hostname)'
              />
            </Grid>
          </Grid>

          {/* Service Characteristics */}
          <Typography variant="h6" sx={{ mt: 3, mb: 1 }}>Service Characteristics</Typography>
          <Box key={index}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                {/* Infra Service Dropdown */}
                <FormControl fullWidth sx={{ mb: 2 }} disabled={!selectedServiceType}>
                  <TextField
                    required
                    select
                    label="Infrastructure Service"
                    value={selectedInfraService}
                    InputLabelProps={{ shrink: true }}
                    onChange={e => setSelectedInfraService(e.target.value)}
                  >
                    {infraServiceOptions && infraServiceOptions.length > 0 ? (
                      infraServiceOptions.map(option => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))) : (
                      <MenuItem value="">No Infrastructure Service found</MenuItem>
                    )}
                  </TextField>
                </FormControl>
              </Grid>
              {/* Provider Selection */}
              <Grid item xs={4}>
                <FormControl fullWidth sx={{ mb: 2 }} disabled={!selectedInfraService}>
                  <TextField
                    required
                    select
                    label="Provider"
                    value={selectedProvider}
                    InputLabelProps={{ shrink: true }}
                    onChange={e => setSelectedProvider(e.target.value)}
                  >
                    {providerOptions && providerOptions.length > 0 ? (
                      providerOptions && providerOptions.map(option => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))) : (
                      <MenuItem value="">No provider found, Select Infrastructure Service</MenuItem>
                    )}
                  </TextField>
                </FormControl>
              </Grid>

              {/* Location Selection */}
              <Grid item xs={4}>
                <FormControl fullWidth sx={{ mb: 2 }} disabled={!selectedProvider}>
                  <Autocomplete
                    required
                    autocomplete
                    disabled={!selectedProvider}
                    options={availableLocationOptions}
                    value={selectedLocation}
                    name="location"
                    onChange={(event, newValue) => setSelectedLocation(newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Available Locations *"
                        placeholder="Select a location"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          ...params.InputProps,
                          notched: true,
                        }}
                        variant="outlined"
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              {/* Deployment Flavour Selection */}
              <Grid item xs={4}>
                <FormControl fullWidth disabled={!selectedProvider || !selectedLocation}>
                  <TextField
                    select
                    required
                    label="Deployment Flavour"
                    InputLabelProps={{ shrink: true }}
                    value={selectedDeploymentFlavour || ''}
                    onChange={(e) => setSelectedDeploymentFlavour(e.target.value)}
                  >
                    {deploymentFlavourOptions && deploymentFlavourOptions.length > 0 ? (
                      deploymentFlavourOptions.length > 0) && deploymentFlavourOptions.map((flavour) => (
                        <MenuItem key={flavour} value={flavour}>{flavour}</MenuItem>
                      )) : (
                      <MenuItem value="">No Deployment Flavour found</MenuItem>
                    )}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <Autocomplete
                    required
                    autocomplete
                    name="csrProject"
                    options={projectOptions || ''}
                    value={item.service.serviceCharacteristic.find(c => c.name === 'csrProject')?.value || ''}
                    onChange={(event, newValue) => handleNestedChange(newValue, 'csrProject', 'serviceCharacteristic')}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Customer Project *"
                        placeholder="Select a project"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          ...params.InputProps,
                          notched: true,
                        }}
                        variant="outlined"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <TextField
                  disabled
                  label="Matched Service Specification (Catalog ID)"
                  name="id"
                  fullWidth
                  required
                  InputLabelProps={{ shrink: true }}
                  value={item.service.serviceSpecification?.id || ''}
                // onChange={(e) => handleNestedChange(e, index, 'serviceSpecification')}
                />
              </Grid>
              <Grid item xs={6} sx={{ mt: 2 }} className="grid-item">
                <CustomDatePicker
                  disabled={!selectedDeploymentFlavour}
                  minDate={tomorrow}
                  selectedDate={selectedServiceStartDate}
                  setSelectedDate={(date) => handleDateChange(date, 'startDate')}
                  label="Service Start Date"
                  helperText="Select Deployment flavour before selecting Service Date"
                />
              </Grid>
              <Grid item xs={6} sx={{ mt: 2 }} className="grid-item">
                <CustomDatePicker
                  helperText="Select Deployment flavour before selecting service end date"
                  disabled={!selectedDeploymentFlavour}
                  minDate={initialServiceEndDate}
                  selectedDate={selectedServiceEndDate}
                  setSelectedDate={(date) => handleDateChange(date, 'endDate')}
                  label="Service End Date"
                />
              </Grid>
            </Grid>

            {/* Intranet Access */}
            <Typography variant="h6" sx={{ mt: 3, mb: 1 }}>Intranet Access</Typography>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  label="Port ID"
                  name="intranetAccess_zPortId"
                  required
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={item.service.serviceCharacteristic.find(c => c.name === 'intranetAccess_zPortId')?.value || ''}
                  onChange={(e) => handleNestedChange(e.target.value, 'intranetAccess_zPortId', 'serviceCharacteristic')}
                />
              </Grid>
              <Grid item xs={4} sx={{ zIndex: 0 }}>
                <TextField
                  label="Port VLAN"
                  required
                  type="number"
                  name="intranetAccess_zVLan"
                  fullWidth
                  error={intraZVLanError} // Show error if true
                  helperText={intraZVLanError ? 'Invalid input. Range is from 2 to 4094.' : 'Range from 2 to 4094'}
                  InputProps={{
                    inputProps: {
                      min: 2,
                      max: 4094,
                    },
                  }}
                  InputLabelProps={{ shrink: true }}
                  value={item.service.serviceCharacteristic.find(c => c.name === 'intranetAccess_zVLan')?.value || ''}
                  onChange={(e) => {
                    const inputValue = e.target.value;

                    // Allow user to input anything, but validate the input
                    if (inputValue === '' || (Number(inputValue) >= 2 && Number(inputValue) <= 4094)) {
                      handleNestedChange(Number(inputValue), 'intranetAccess_zVLan', 'serviceCharacteristic');
                      setIntra_zVLanError(false);  // No error when input is valid
                    } else {
                      setIntra_zVLanError(true);   // Set error when out of range
                    }
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  required
                  label="Bandwidth"
                  name="intranetAccess_bandwidth"
                  fullWidth
                  type='number'
                  InputLabelProps={{ shrink: true }}
                  value={item.service.serviceCharacteristic.find(c => c.name === 'intranetAccess_bandwidth')?.value || ''}
                  onChange={(e) => {
                    const inputValue = e.target.value;

                    // Allow empty input or any characters initially
                    handleNestedChange(inputValue, 'intranetAccess_bandwidth', 'serviceCharacteristic');
                  }}
                  onBlur={(e) => {
                    const inputValue = e.target.value;

                    // When losing focus, validate and convert to number if applicable
                    const numericValue = inputValue === '' ? '' : Number(inputValue);

                    if (!isNaN(numericValue)) {
                      handleNestedChange(numericValue, 'intranetAccess_bandwidth', 'serviceCharacteristic');
                    } else {
                      // If it's not a valid number, reset or show an error (optional)
                      handleNestedChange('', 'intranetAccess_bandwidth', 'serviceCharacteristic');
                    }
                  }}
                />

              </Grid>
              <Grid item xs={4} sx={{ zIndex: 0 }}>
                <TextField
                  label="Port Location"
                  name="intranetAccesslocation"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={item.service.serviceCharacteristic.find(c => c.name === 'intranetAccess_location')?.value || ''}
                  onChange={(e) => handleNestedChange(e.target.value, 'intranetAccess_location', 'serviceCharacteristic')}
                />
              </Grid>
              <Grid item xs={4} sx={{ zIndex: 0 }}>
                <TextField
                  label="Service Provider Name"
                  name="intranetAccess_name"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={item.service.serviceCharacteristic.find(c => c.name === 'intranetAccess_name')?.value || ''}
                  onChange={(e) => handleNestedChange(e.target.value, 'intranetAccess_name', 'serviceCharacteristic')}
                />
              </Grid>
              <Grid item xs={4} sx={{ zIndex: 0 }}>
                <TextField
                  label="Service Provider ID"
                  name="intranetAccess_id"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={item.service.serviceCharacteristic.find(c => c.name === 'intranetAccess_id')?.value || ''}
                  onChange={(e) => handleNestedChange(e.target.value, 'intranetAccess_id', 'serviceCharacteristic')}
                />
              </Grid>
            </Grid>

            {/* Internet Access */}
            <Typography variant="h6" sx={{ mt: 3, mb: 1 }}>Internet Access</Typography>
            <Grid container spacing={2} sx={{ mb: 1 }}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    select
                    required
                    label="Type"
                    InputLabelProps={{ shrink: true }}
                    value={item.service.serviceCharacteristic.find(c => c.name === 'internetAccess_type')?.value || 'default'}
                    onChange={(e) => handleNestedChange(e, 'internetAccess_type', 'serviceCharacteristic')}
                  >
                    <MenuItem key="default" value="default">Default</MenuItem>
                    <MenuItem disabled key="other" value="other">Other</MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
            </Grid>
            {/* {isInternetAccessDefault === "other" && <Grid container spacing={2}>
            <Grid item xs={4}>
            <TextField
                label="Service Provider Name"
                name="internetAccess_serviceProviderName"
                fullWidth
                value={data.internetAccess_serviceProviderName}
                onChange={(e) => handleNestedChange(e, index, 'serviceCharacteristic')}
            />
            </Grid>
            <Grid item xs={4}>
            <TextField
                label="Service Provider ID"
                name="internetAccess_serviceProviderId"
                fullWidth
                value={data.internetAccess_serviceProviderId}
                onChange={(e) => handleNestedChange(e, index, 'serviceCharacteristic')}
            />
            </Grid>
            <Grid item xs={4}>
            <TextField
                label="Bandwidth"
                name="internetAccess_bandwidth"
                fullWidth
                type='number'
                value={data.internetAccess_bandwidth}
                onChange={(e) => handleNestedChange(e, index, 'serviceCharacteristic')}
            />
            </Grid>
            <Grid item xs={4}>
            <TextField
                label="Port Location"
                name="internetAccess_portLocation"
                fullWidth
                value={data.internetAccess_portLocation}
                onChange={(e) => handleNestedChange(e, index, 'serviceCharacteristic')}
            />
            </Grid>
            <Grid item xs={4}>
            <TextField
                label="Zport ID"
                required
                name="internetAccess_zPortId"
                fullWidth
                value={data.internetAccess_zPortId}
                onChange={(e) => handleNestedChange(e, index, 'serviceCharacteristic')}
            />
            </Grid>
            <Grid item xs={4}>
            <TextField
                label="Zport VLAN"
                required
                name="internetAccess_zVLan"
                fullWidth
                value={data.internetAccess_zVLan}
                onChange={(e) => handleNestedChange(e, index, 'serviceCharacteristic')}
            />
            </Grid>
        </Grid>} */}
          </Box>

        </Box>
      ))}
    </Box>
  );
};

export default AddServices;
