import React from 'react';
import { Typography, Box} from '@mui/material';
import DataTable from './DataTable';
import { useSelector } from 'react-redux';

const DigitalProviderComponent = ({columns}) => {
  const { digitalServiceProviderList } = useSelector((state) => state.digitalProvider);

  return (
    <Box>
      <Box sx={{ boxShadow: 3, borderRadius: 2, p: 2, overflow: 'hidden' }}>
        <Typography variant="h5" sx={{ mb: 2}}>Digital Service Provider</Typography>
        <DataTable
          columns={columns}
          data={digitalServiceProviderList}
          enableStickyHeader
          getRowId={(row) => row.id}
          enableExpandAll={false}
          enableTopToolbar={false}
          enablePagination={false}
          enableBottomToolbar={false}
        />
      </Box>
    </Box>
  );
};

export default DigitalProviderComponent;