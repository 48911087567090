import React from 'react';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { fetchServiceCatalogs } from '../../store/serviceCatalogSlice';
import CreateNewOrderWizard from './createFormSteps/CreateNewOrderWizard';

const CreateNewServiceOrder = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchServiceCatalogs());
  }, [dispatch]);

  return (
    <Box>
      <CreateNewOrderWizard />
    </Box>
  );
};

export default CreateNewServiceOrder;