import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Button,
  Grid,
  IconButton, Paper
} from '@mui/material';
import DataTable from '../global/DataTable';
import { fetchServiceCatalogByID } from '../../store/serviceCatalogSlice';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DynamicIcon from '../global/DynamicIcon';
import AlertComponent from '../global/AlertComponent';
// import DeliveryPointCards from "./DeliveryPointsCards";

const ServiceCatalogDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const { serviceCatalogById, loading, catalogueDeliveryPoints, networkData, catalogError } = useSelector((state) => state.serviceCatalog || {});

  const columns = useMemo(() => [
    { accessorKey: 'provider', header: 'Provider', enableColumnFilter: false, enableColumnActions: false, size: 20 },
    { accessorKey: 'infraService', header: 'Infrastructure Service', enableColumnFilter: false, enableColumnActions: false, minSize: 10, size: 20 },
    { accessorKey: 'availableLocation', header: 'Available location', filterable: true, minSize: 10, size: 20 },
    {
      accessorKey: 'edit',
      header: '',
      enableColumnFilter: false,
      filtering: false,
      sortable: false,
      minSize: 5, size: 15,
      enableColumnActions: false,
      Cell: () => (
        <IconButton disabled={true}>
            <DynamicIcon iconName="EditOutlined" sx={{ color: "#00000042", fontSize: 14 }} />
        </IconButton>
      ),
    },
  ], []);

  const [expanded, setExpanded] = useState(true);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    dispatch(fetchServiceCatalogByID(id));
  }, [dispatch, id]);

  // Handler functions for buttons
  const handleUpdateServiceCatalog = () => {
    console.log("Update Service Order clicked");
  };

  const handleDeleteServiceCatalog = () => {
    console.log("Delete Service Order clicked");
  };
  const handleModify = (row) => {
    console.log('Modify row:', row);
  };

  const renderValue = (value) => {
    if (typeof value === 'object' && value !== null) {
      return JSON.stringify(value);
    }
    return value || 'N/A';
  };

  return (
    <Box sx={{ maxWidth: '95%' }}>
      <Button sx={{ mb: 2 }} onClick={() => navigate(-1)} variant="outlined" color="secondary">
        <DynamicIcon iconName="ArrowBack" sx={{ fontSize: '18px' }} />
      </Button>
      {catalogError && catalogError?.message ? (
        <AlertComponent
          severity="error"
          title={catalogError?.reason}
          description={catalogError?.message}
        />
      ) : !loading ? (
        <Box sx={{m: 'auto', width: '95%', maxWidth: '1024px', textAlign: 'center' }}>
          <Box sx={{ 
              maxWidth: {
                xs: '100%', // Extra small screens
                sm: '600px', // Small screens
                md: '800px', // Medium screens
                lg: '1024px', // Large screens
              }, textAlign: 'center'}}>
            <Accordion expanded={expanded} onChange={handleChange} sx={{ borderRadius: 1 }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h5">Service Catalog Details</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer component={Paper} sx={{ minWidth: 650, borderRadius: 0 }}>
                  <Table sx={{ tableLayout: 'fixed' }} size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow sx={{ backgroundColor: '#D3D3D3' }}>
                        <TableCell sx={{ color: '#000', fontWeight: '600', fontSize: '14px' }}>Field</TableCell>
                        <TableCell sx={{ color: '#000', fontWeight: '600', fontSize: '14px' }}>Value</TableCell>
                        <TableCell sx={{ color: '#000', fontWeight: '600', fontSize: '14px' }}>Modify</TableCell>
                      </TableRow>
                    </TableHead>
                    {serviceCatalogById?.length > 0 && serviceCatalogById.map((value, index) => (
                      <TableBody sx={{ textAlign: 'center' }} key={index}>
                        <TableRow>
                          <TableCell sx={{ fontWeight: 600, fontSize: '14px' }} component="th" scope="row">UUID</TableCell>
                          <TableCell>{renderValue(value.service_catalog_uuid)}</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{ fontWeight: 600, fontSize: '14px' }} component="th" scope="row">Category</TableCell>
                          <TableCell>{renderValue(value.category)}</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{ fontWeight: 600, fontSize: '14px' }} component="th" scope="row">Service Type</TableCell>
                          <TableCell>{renderValue(value.service_type)}</TableCell>
                          <TableCell>
                            <IconButton
                              sx={{ padding: 0 }}
                              disabled
                              onClick={() => handleModify(value.service_type)}
                            >
                              <DynamicIcon iconName="EditOutlined" sx={{ fontSize: '18px' }} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{ fontWeight: 600, fontSize: '14px' }} component="th" scope="row">Name</TableCell>
                          <TableCell>{renderValue(value.name)}</TableCell>
                          <TableCell>
                            <IconButton
                              sx={{ padding: 0 }}
                              disabled
                              onClick={() => handleModify(value.name)}
                            >
                              <DynamicIcon iconName="EditOutlined" sx={{ fontSize: '18px' }} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{ fontWeight: 600, fontSize: '14px' }} component="th" scope="row">State</TableCell>
                          <TableCell sx={{ textTransform: 'capitalize' }}>{renderValue(value.state)}</TableCell>
                          <TableCell>
                            <IconButton
                              sx={{ padding: 0 }}
                              disabled
                              onClick={() => handleModify(value.state)}
                            >
                              <DynamicIcon iconName="EditOutlined" sx={{ fontSize: '18px' }} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{ fontWeight: 600, fontSize: '14px' }} component="th" scope="row">Version</TableCell>
                          <TableCell>{renderValue(value.version)}</TableCell>
                          <TableCell>
                            <IconButton
                              sx={{ padding: 0 }}
                              disabled
                              onClick={() => handleModify(value.version)}
                            >
                              <DynamicIcon iconName="EditOutlined" sx={{ fontSize: '18px' }} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{ fontWeight: 600, fontSize: '14px' }} component="th" scope="row">Min Service Lifetime (mins)</TableCell>
                          <TableCell>{renderValue(value.minimum_service_lifetime)}</TableCell>
                          <TableCell>
                            <IconButton
                              sx={{ padding: 0 }}
                              disabled
                              onClick={() => handleModify(value.minimum_service_lifetime)}
                            >
                              <DynamicIcon iconName="EditOutlined" sx={{ fontSize: '18px' }} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{ fontWeight: 600, fontSize: '14px' }} component="th" scope="row">Max Service Provision Time (mins)</TableCell>
                          <TableCell>{value.maximum_service_provisioning_time}</TableCell>
                          <TableCell>
                            <IconButton
                              sx={{ padding: 0 }}
                              disabled
                              onClick={() => handleModify(value.maximum_service_provisioning_time)}
                            >
                              <DynamicIcon iconName="EditOutlined" sx={{ fontSize: '18px' }} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))}
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>

            <Accordion sx={{ borderRadius: 1 }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h5">Network Function Details</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer component={Paper} sx={{ minWidth: 650, borderRadius: 0 }}>
                  <Table sx={{ tableLayout: 'fixed' }} size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow sx={{ backgroundColor: '#D3D3D3' }}>
                        <TableCell sx={{ color: '#000', fontWeight: '600', fontSize: '14px' }}>Specification</TableCell>
                        <TableCell>
                          <IconButton
                            sx={{ padding: 0 }}
                            disabled
                          >
                            <DynamicIcon iconName="EditOutlined" sx={{ fontSize: '18px' }} />
                          </IconButton>
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow sx={{ textTransform: 'capitalize' }}>
                        <TableCell >
                          <strong>Deployment Flavour</strong>
                        </TableCell >
                        <TableCell >
                          {networkData.deploymentFlavour}
                        </TableCell>
                        <TableCell >
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ textTransform: 'capitalize' }}>
                        <TableCell >
                          <strong>Vendor</strong>
                        </TableCell >
                        <TableCell >
                          {networkData.vendor}
                        </TableCell>
                        <TableCell >
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ textTransform: 'capitalize' }}>
                        <TableCell >
                          <strong>State</strong>
                        </TableCell >
                        <TableCell sx={{ textTransform: 'capitalize' }}>
                          {networkData.nfState}
                        </TableCell>
                        <TableCell >
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ textTransform: 'capitalize' }}>
                        <TableCell >
                          <strong>CPU Architecture</strong>
                        </TableCell>
                        <TableCell >
                          {networkData.cpuArchitecture}
                        </TableCell>
                        <TableCell >
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ textTransform: 'capitalize' }}>
                        <TableCell >
                          <strong>CPU Count</strong>
                        </TableCell >
                        <TableCell >
                          {networkData.cpuCount}
                        </TableCell>
                        <TableCell >
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ textTransform: 'capitalize' }}>
                        <TableCell >
                          <strong>Memory (GB)</strong>
                        </TableCell >
                        <TableCell >
                          {networkData.memory}
                        </TableCell>
                        <TableCell >
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ textTransform: 'capitalize' }}>
                        <TableCell>
                          <strong>Storage (GB)</strong>
                        </TableCell>
                        <TableCell>
                          {networkData.storage}
                        </TableCell>
                        <TableCell>
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ textTransform: 'capitalize' }}>
                          <TableCell >
                            <strong>nfLocation</strong>
                          </TableCell >
                          <TableCell >
                            {networkData.nfLocation}
                          </TableCell>
                          <TableCell >
                          </TableCell>
                        </TableRow>
                      <TableRow sx={{ textTransform: 'capitalize' }}>
                          <TableCell >
                            <strong>nfImage</strong>
                          </TableCell >
                          <TableCell >
                            {networkData.nfImage}
                          </TableCell>
                          <TableCell >
                          </TableCell>
                        </TableRow>
                      <TableRow sx={{ textTransform: 'capitalize' }}>
                        <TableCell >
                          <strong>nfConfigurationScript</strong>
                        </TableCell >
                        <TableCell >
                          {networkData.nfConfigurationScript}
                        </TableCell>
                        <TableCell >
                        </TableCell>
                      </TableRow>
                        <TableRow sx={{ textTransform: 'capitalize' }}>
                          <TableCell >
                            <strong>Maximum Bandwidth per Instance (Mbps)</strong> 
                          </TableCell >
                          <TableCell >
                            {networkData.maxBandwidth}
                          </TableCell>
                          <TableCell >
                          </TableCell>
                        </TableRow>
                        <TableRow sx={{ textTransform: 'capitalize' }}>
                          <TableCell >
                            <strong>Device Type Code</strong> 
                          </TableCell >
                          <TableCell >
                            {networkData.deviceTypeCode}
                          </TableCell>
                          <TableCell >
                          </TableCell>
                        </TableRow>
                        <TableRow sx={{ textTransform: 'capitalize' }}>
                          <TableCell >
                            <strong>Software Package</strong> 
                          </TableCell >
                          <TableCell >
                            {networkData.softwarePackage}
                          </TableCell>
                          <TableCell >
                          </TableCell>
                        </TableRow>
                        <TableRow sx={{ textTransform: 'capitalize' }}>
                          <TableCell >
                            <strong>Software Version</strong> 
                          </TableCell >
                          <TableCell >
                            {networkData.softwareVersion}
                          </TableCell>
                          <TableCell >
                          </TableCell>
                        </TableRow>
                        <TableRow sx={{ textTransform: 'capitalize' }}>
                          <TableCell >
                            <strong>Connectivity Type</strong> 
                          </TableCell >
                          <TableCell >
                            {networkData.connectivityType}
                          </TableCell>
                          <TableCell >
                          </TableCell>
                        </TableRow>
                        <TableRow sx={{ textTransform: 'capitalize' }}>
                          <TableCell >
                            <strong>Deployment Type</strong> 
                          </TableCell >
                          <TableCell >
                            {networkData.deploymentType}
                          </TableCell>
                          <TableCell >
                          </TableCell>
                        </TableRow>
                        <TableRow sx={{ textTransform: 'capitalize' }}>
                          <TableCell >
                            <strong>License Type</strong> 
                          </TableCell >
                          <TableCell >
                            {networkData.licenseType}
                          </TableCell>
                          <TableCell >
                          </TableCell>
                        </TableRow>
                        <TableRow sx={{ textTransform: 'capitalize' }}>
                          <TableCell >
                            <strong>License Format</strong> 
                          </TableCell >
                          <TableCell >
                            {networkData.licenseFormat}
                          </TableCell>
                          <TableCell >
                          </TableCell>
                        </TableRow>
                        <TableRow sx={{ textTransform: 'capitalize' }}>
                          <TableCell >
                            <strong>Number of Interfaces</strong> 
                          </TableCell >
                          <TableCell >
                            {networkData.numberOfInterfaces}
                          </TableCell>
                          <TableCell >
                          </TableCell>
                        </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{ borderRadius: 1 }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h5">Delivery Points</Typography>
              </AccordionSummary>
              <AccordionDetails>

              {/* <DeliveryPointCards deliveryPoints={catalogueDeliveryPoints} /> */}
              {catalogueDeliveryPoints?.length > 0 ? (
                <DataTable
                  columns={columns}
                  data={catalogueDeliveryPoints}
                  enableStickyHeader
                  getRowId={(row) => row.id}
                  enableExpandAll={false}
                  enableTopToolbar={true}
                />
                ) : (
                  <Typography variant="body1" color="error" align="center">
                    No delivery points found
                  </Typography>
                )}
              </AccordionDetails>
            </Accordion>

            <Grid container spacing={2} sx={{ mt: 3, mb: 3 }}>
              {/* Div 1: Update Service Order */}
              <Grid item xs={6} sx={{ textAlign: 'center' }}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleUpdateServiceCatalog}
                  disabled
                  size='large'
                >
                  Update Catalogue item
                </Button>
              </Grid>

              {/* Div 2: Delete Service Order */}
              <Grid item xs={6} sx={{ textAlign: 'center' }}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleDeleteServiceCatalog}
                  disabled
                  size='large'
                >
                  Delete Catalogue item
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      ) : (
        <Typography>Loading...</Typography>
      )
      }
    </Box >
  );
};

export default ServiceCatalogDetails;