const UserDisabledPage = () => {
    return (
      <div>
        <h1>Your account is disabled.</h1>
        <p>Please contact support for more information.</p>
      </div>
    );
  };
  
  export default UserDisabledPage;
  