import './App.css';
import React, { useEffect, useState, createContext } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import Footer from './components/global/Footer';
import Login from './components/Login';
import DashboardLayout from './components/layout/DashboardLayout';
import { theme } from './api/theme';
import { fetchUserRolesAndPermissions } from './store/userSlice';
// import { fetchServiceInstances } from './store/serviceInstanceSlice';
// import { fetchServiceOrders } from './store/serviceOrderSlice';

export const AppContext = createContext();

const App = () => {
  const dispatch = useDispatch();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    const userEmail = localStorage.getItem('userEmail');
    setIsAuthenticated(!!token);
    
    if(isAuthenticated) {
      if(userEmail) {
        dispatch(fetchUserRolesAndPermissions(userEmail));
      }
      // dispatch(fetchServiceInstances());
      // dispatch(fetchServiceOrders());
    }
  }, [dispatch, isAuthenticated]);

  return (
    <AppContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
      <ThemeProvider theme={theme}>
        <Router>
          <AppRoutes isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />
          <Footer />
        </Router>
      </ThemeProvider>
    </AppContext.Provider>
  );
};

const AppRoutes = ({ isAuthenticated, setIsAuthenticated }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const loginTimestamp = localStorage.getItem('loginTimestamp');
    
    if (loginTimestamp) {
      const timeElapsed = Date.now() - loginTimestamp;
      const expiresIn = process.env.REACT_APP_LOGOUT_TIME;

      if (timeElapsed >= expiresIn) {
        // Move handleLogout logic inside useEffect
        localStorage.removeItem('loginTimestamp');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('userEmail');
        setIsAuthenticated(false);
        navigate('/login');
      } else {
        const remainingTime = expiresIn - timeElapsed;
        const logoutTimer = setTimeout(() => {
          // Same logout logic in the timeout callback
          localStorage.removeItem('loginTimestamp');
          localStorage.removeItem('accessToken');
          localStorage.removeItem('refreshToken');
          localStorage.removeItem('userEmail');
          setIsAuthenticated(false);
          navigate('/login');
        }, remainingTime);

        return () => clearTimeout(logoutTimer);
      }
    }
  }, [navigate, setIsAuthenticated]);

  return (
    <Routes>
      <Route
        path="/login"
        element={
          isAuthenticated ? (
            <Navigate to={location.state?.from || '/dashboard'} />
          ) : (
            <Login setIsAuthenticated={setIsAuthenticated} />
          )
        }
      />
      <Route
        path="/*"
        element={
          isAuthenticated ? (
            <DashboardLayout />
          ) : (
            <Navigate to="/login" state={{ from: location.pathname }} />
          )
        }
      />
    </Routes>
  );
};

export default App;


